const TOKEN_KEY = "jwt-token";
const TOKEN_USER_FIRST_NAME = "firstName";
const TOKEN_USER_LAST_NAME = "lastName";
const TOKEN_USER_PATRONYMIC_NAME = "patronymic";
const TOKEN_USER_PHONE = "phone";

export interface IUserInfo {
  firstName: string;
  lastName: string;
  patronymic: string;
  phone: string;
}

export async function setTokens({ accessToken }: { accessToken: string }) {
  await localStorage.setItem(TOKEN_KEY, accessToken);
}

export async function removeAuthData() {
  await localStorage.removeItem(TOKEN_KEY);
}

export function getAcesToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export async function setInfoUser({
  firstName,
  lastName,
  patronymic,
}: IUserInfo) {
  await localStorage.setItem(TOKEN_USER_FIRST_NAME, firstName);
  await localStorage.setItem(TOKEN_USER_LAST_NAME, lastName);
  await localStorage.setItem(TOKEN_USER_PATRONYMIC_NAME, patronymic);
}

export async function setUserPhone(phone: string) {
  await localStorage.setItem(TOKEN_USER_PHONE, phone);
}


export function getInfoUser() {
  return {
    firstName: localStorage.getItem(TOKEN_USER_FIRST_NAME),
    lastName: localStorage.getItem(TOKEN_USER_LAST_NAME),
    patronymic: localStorage.getItem(TOKEN_USER_PATRONYMIC_NAME),
    phone: localStorage.getItem(TOKEN_USER_PHONE),
  };
}

export function removeInfoUser() {
  localStorage.removeItem(TOKEN_USER_FIRST_NAME);
  localStorage.removeItem(TOKEN_USER_LAST_NAME);
  localStorage.removeItem(TOKEN_USER_PATRONYMIC_NAME);
  localStorage.removeItem(TOKEN_USER_PHONE);
}

const localStorageServices = {
  setTokens,
  getAcesToken,
  removeAuthData,
};

export default localStorageServices;
