import { useEffect, useState } from "react";
import { ISelectItem, Select, TSelectValue } from "../../../../../ui/select";
import InputSQMeter from "../../../../../ui/input/input-sq-meter";
import ItemsYmap from "../../../../../ui/map/itemYmap";
import Loader from "../../../../../ui/loader";
import Button from "../../../../../ui/button";
import { ISelectItemExpended } from "../../../interfaces";
import useComplex from "./hooks/useComplex";
import useStreet from "./hooks/useStreet";
import useBuildingComplex from "./hooks/useBuildingComplex";
import useTypes from "./hooks/useTypes";
import useBuildingSecondary from "./hooks/useBuilding";
import useArea from "./hooks/useArea";
import useGetMarketPrice from "./hooks/useGetMarketPrice";
import useReset from "./hooks/useReset";
import clsx from "clsx";
import ModalSupportDeal from "../../modals/modalAnalogs/modalSupportDeal";
import { getAddress } from "./helpers";
import { useDataEstimateContext } from "../../../hooks/useModalContex";
import {
  IPrimaryFacilityEstimateResponseWithAddress,
  ISecondaryFacilityEstimateResponseWithAddress,
} from "../../..";

import styles from "./styles.module.scss";

interface IFormEstimateMarketPriceProps {
  isSecondarySwitcher: boolean;
  onCallBackData: (
    data:
      | ISecondaryFacilityEstimateResponseWithAddress
      | IPrimaryFacilityEstimateResponseWithAddress
  ) => void;
  resetData: () => void;
}

const FormEstimateMarketPrice = ({
  isSecondarySwitcher,
  onCallBackData,
  resetData,
}: IFormEstimateMarketPriceProps) => {
  /// modal support ///

  const [isOpenModalSupport, setIsOpenModalSupport] = useState(false);

  const handleChangeModalSupport = (value: boolean) => {
    setIsOpenModalSupport(value);
  };

  ///---Primary---///

  /// complexes
  const {
    complexes,
    selectedComplex,
    isLoadingComplexes,
    handleSetComplex,
    handleResetComplex,
  } = useComplex(!isSecondarySwitcher);
  /// complexBuildings
  const {
    selectedBuildingPrimary,
    buildingsComplex,
    isLoadingBuildingComplexes,
    handleSetComplexBuilding,
    handleResetBuildingComplex,
  } = useBuildingComplex(selectedComplex);
  /// typesApartment
  const {
    typesApartment,
    selectedTypesApartment,
    minMaxArea,
    isLoadingTypes,
    isLoadingMinMaxArea,
    handleSetTypesApartment,
    handleResetTypesApartment,
  } = useTypes(selectedBuildingPrimary);

  ///---Secondary---///

  /// street
  const {
    streets,
    selectedStreet,
    isLoadingStreets,
    handleSetStreet,
    handleResetStreet,
  } = useStreet(isSecondarySwitcher);
  /// buildingSecondary
  const {
    selectedBuildingSecondary,
    buildingsSecondary,
    errorBuildings,
    isLoadingBuildings,
    handleSetBuilding,
    handleResetBuilding,
  } = useBuildingSecondary(
    (streets as ISelectItem[]).length ? (streets as ISelectItem[]) : [],
    selectedStreet
  );

  ///---Common---//

  /// apartmentArea
  const {
    areaInSqM,
    errorAreaInSqM,
    checkValid,
    handleChangeApartmentArea,
    handleResetArea,
  } = useArea(isSecondarySwitcher, minMaxArea);
  /// getMarketPrice
  const {
    onSubmit,
    dataSecondary,
    dataPrimary,
    isMutatingSecondary,
    isMutatingPrimary,
  } = useGetMarketPrice(
    isSecondarySwitcher,
    handleChangeModalSupport,
    resetData
  );

  const data = isSecondarySwitcher ? dataSecondary : dataPrimary;
  const isMutating = isSecondarySwitcher
    ? isMutatingSecondary
    : isMutatingPrimary;

  const getMarketPrice = () => {
    onSubmit(
      checkValid,
      selectedBuildingSecondary,
      areaInSqM,
      selectedBuildingPrimary,
      selectedComplex,
      selectedTypesApartment
    );
  };

  const address = getAddress(
    isSecondarySwitcher,
    streets as ISelectItemExpended[],
    buildingsSecondary as ISelectItemExpended[],
    complexes as ISelectItemExpended[],
    buildingsComplex as ISelectItemExpended[],
    selectedStreet,
    selectedBuildingSecondary,
    selectedComplex,
    selectedBuildingPrimary
  );

  useEffect(() => {
    if (data) {
      onCallBackData({
        ...data,
        address,
      });
    }
  }, [data]);

  /// reset
  const {} = useReset(
    isSecondarySwitcher,
    {
      handleResetStreet,
      handleResetBuilding,
      handleResetComplex,
      handleResetBuildingComplex,
      handleResetTypesApartment,
      handleResetArea,
    },
    selectedStreet,
    selectedComplex,
    selectedBuildingSecondary,
    selectedBuildingPrimary,
    selectedTypesApartment
  );

  /// values
  const streetsOrComplex = isSecondarySwitcher ? streets : complexes;
  const selectedStreetOrComplex = isSecondarySwitcher
    ? selectedStreet
    : selectedComplex;
  const selectedBuilding = isSecondarySwitcher
    ? selectedBuildingSecondary
    : selectedBuildingPrimary;
  const buildings = isSecondarySwitcher ? buildingsSecondary : buildingsComplex;

  const currentCoords = (function () {
    const key = isSecondarySwitcher
      ? selectedBuildingSecondary
      : selectedBuildingPrimary;

    return (buildings as ISelectItemExpended[])?.find(
      (building) => building.key === key
    )?.coordinates;
  })();

  const typeApartment = (function () {
    if (selectedTypesApartment) {
      return (typesApartment as ISelectItem[]).find(
        (type) => type.key === selectedTypesApartment
      )?.label as string;
    }
    return undefined;
  })();

  /// useDataEstimateContext
  const { onSetDataModal } = useDataEstimateContext();

  useEffect(() => {
    if (isSecondarySwitcher) {
      if (areaInSqM && address) {
        onSetDataModal({
          address,
          areaInSqM,
        });
      }
    } else {
      if (areaInSqM && address && typeApartment && selectedTypesApartment) {
        onSetDataModal({
          address,
          areaInSqM,
          typeApartment,
          typeApartmentId: selectedTypesApartment,
        });
      }
    }
  }, [areaInSqM, address, typeApartment]);

  const onSelectStreetsOrComplex = (value: TSelectValue) => {
    if (isSecondarySwitcher) {
      handleSetStreet(value);
    } else {
      handleSetComplex(value);
    }
  };

  const onSelectBuilding = (value: TSelectValue) => {
    if (isSecondarySwitcher) {
      handleSetBuilding(value);
    } else {
      handleSetComplexBuilding(value);
    }
  };

  const isDisableBuildingSelect = isSecondarySwitcher
    ? !Boolean(selectedStreet)
    : !Boolean(selectedComplex);

  const isAllSelected = !isDisableBuildingSelect && areaInSqM;
  const isLoading =
    isLoadingComplexes ||
    isLoadingBuildingComplexes ||
    isLoadingStreets ||
    isLoadingBuildings ||
    isLoadingTypes;

  return (
    <div className={styles.form}>
      <div className={styles.form__container}>
        <div className={styles.form__loader}>
          {isLoading && <Loader></Loader>}
        </div>

        <div
          className={clsx(
            styles.form__card,
            isLoading && styles.form__card_loading,
            isSecondarySwitcher && styles.form__card_green
          )}
        >
          <div className={styles.form__title}>
            <h4>Заполните, и мы оценим ваше жилье:</h4>
          </div>

          <form
            className={styles.form__form}
            onSubmit={(e) => e.preventDefault()}
          >
            <Select
              search
              onSelect={onSelectStreetsOrComplex}
              multiple={false}
              menuArray={
                (streetsOrComplex as ISelectItem[])?.length
                  ? (streetsOrComplex as ISelectItem[])
                  : []
              }
              selected={selectedStreetOrComplex}
              placeholder={isSecondarySwitcher ? "Укажите адрес" : "Укажите ЖК"}
              label={isSecondarySwitcher ? "Адрес" : "ЖК"}
              isMainPlaceholder
            />

            <Select
              search
              onSelect={onSelectBuilding}
              multiple={false}
              menuArray={
                (buildings as ISelectItemExpended[])?.length
                  ? (buildings as ISelectItemExpended[])
                  : []
              }
              selected={selectedBuilding}
              placeholder={"Укажите дом"}
              label="Дом"
              isMainPlaceholder
              disabled={isDisableBuildingSelect}
            />

            {!isSecondarySwitcher && (
              <Select
                search
                onSelect={handleSetTypesApartment}
                multiple={false}
                menuArray={typesApartment as ISelectItem[]}
                selected={selectedTypesApartment}
                placeholder={"Укажите тип квартиры"}
                label="Тип квартиры"
                isMainPlaceholder
                disabled={!Boolean(selectedBuilding)}
              />
            )}

            <InputSQMeter
              value={areaInSqM}
              onChange={handleChangeApartmentArea}
              error={errorAreaInSqM}
              placeholder={
                minMaxArea
                  ? `От ${minMaxArea?.min} до ${minMaxArea?.max}`
                  : undefined
              }
            />
          </form>

          <div className={styles.form__map}>
            <ItemsYmap
              items={
                currentCoords
                  ? [
                      {
                        id: 1,
                        coords: [
                          currentCoords.latitude,
                          currentCoords.longitude,
                        ],
                        selected: true,
                      },
                    ]
                  : []
              }
              zoom={currentCoords ? 15 : 11}
              isSetCenterByFirstItem={currentCoords ? true : false}
            />
          </div>
          <div className={styles.form__button}>
            <Button
              label="Узнать рыночную стоимость"
              onClick={getMarketPrice}
              disabled={
                isSecondarySwitcher
                  ? !Boolean(isAllSelected)
                  : !Boolean(isAllSelected)
              }
              loading={isMutating}
              variant={isSecondarySwitcher ? "default" : "dark"}
            />
          </div>
        </div>
      </div>
      <ModalSupportDeal
        isOpen={isOpenModalSupport}
        data={{
          areaInSqM: areaInSqM as string,
          typeApartment: typeApartment,
          typeApartmentId: selectedTypesApartment as number,
          address: address,
        }}
        onDismiss={handleChangeModalSupport}
        title={isSecondarySwitcher ? "Заявка на расчет" : "Поддержка"}
        type="support"
      />
    </div>
  );
};
export default FormEstimateMarketPrice;
