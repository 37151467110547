import Button from "../../../../ui/button";
import AccompanimentReitEstimate from "./accompanimentReitEstimate";
import style from "./styles.module.scss";
import { toast } from "react-toastify";
import { useDataEstimateContext } from "../../hooks/useModalContex";
import { useState } from "react";

import ModalTradeIn from "../modals/modalAnalogs/modalTradeIn";
import ModalBy from "../modals/modalAnalogs/modalBy";

interface ISellEstimateProps {
  isSecondary: boolean;
}

const SellEstimate = ({ isSecondary }: ISellEstimateProps) => {
  const { dataModal } = useDataEstimateContext();

  const [isOpenModalBy, setIsOpenModalBy] = useState(false);

  const handleChangeModalBy = (value: boolean) => {
    setIsOpenModalBy(value);
  };

  const handleClickBy = () => {
    if (dataModal) {
      setIsOpenModalBy(true);
    } else {
      toast.error("Что то пошло ни так, ошибка данных");
    }
  };

  const [isOpenModalTradeIn, setIsOpenModalTradeIn] = useState(false);

  const handleChangeModalTradeIn = (value: boolean) => {
    setIsOpenModalTradeIn(value);
  };

  const handleClickTradeIn = () => {
    if (dataModal) {
      setIsOpenModalTradeIn(true);
    } else {
      toast.error("Что то пошло ни так, ошибка данных");
    }
  };

  return (
    <div className={style.sell}>
      <div className={style.sell__container}>
        <div className={style.sell__offer}>
          <div className={style.sell__text}>
            <p>
              Мы готовы предложить вам быструю сделку по цене ниже рыночной
              стоимости:
            </p>
          </div>

          <div className={style.sell__buttons}>
            <Button label="Выкуп" variant="dark" onClick={handleClickBy} />
            <Button label="Trade-in" onClick={handleClickTradeIn} />
          </div>
        </div>

        <div className={style.sell__accompanimentReit}>
          <AccompanimentReitEstimate />
        </div>
      </div>
      {dataModal && (
        <>
          <ModalBy
            isSecondary={isSecondary}
            data={dataModal}
            onDismiss={handleChangeModalBy}
            isOpen={isOpenModalBy}
          />
          <ModalTradeIn
            isSecondary={isSecondary}
            data={dataModal}
            onDismiss={handleChangeModalTradeIn}
            isOpen={isOpenModalTradeIn}
            type="sell"
          />
        </>
      )}
    </div>
  );
};

export default SellEstimate;
