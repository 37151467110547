import DottedList from "../../../../ui/dottedList";
import style from "./styles.module.scss";
import Button from "../../../../ui/button";
import AccompanimentReitEstimate from "./accompanimentReitEstimate";
import { toPercent, toReadablePrice } from "../../../../../lib/utils";
import { IMortgageInfo } from "../../interfaces";
import ModalSubsidizing from "../modals/modalAnalogs/modalSubsidizing";
import { useDataEstimateContext } from "../../hooks/useModalContex";
import { useState } from "react";
import { toast } from "react-toastify";
import ModalTradeIn from "../modals/modalAnalogs/modalTradeIn";

interface IByEstimateProps {
  averageMarketPrice: string;
  mortgageInfo: IMortgageInfo;
  isSecondary: boolean;
}

const ByEstimate = ({
  averageMarketPrice,
  mortgageInfo,
  isSecondary,
}: IByEstimateProps) => {
  const { dataModal } = useDataEstimateContext();

  const [isOpenModalSubsiding, setIsOpenModalSubsiding] = useState(false);

  const handleChangeModalSubsiding = (value: boolean) => {
    setIsOpenModalSubsiding(value);
  };

  const handleClickSubsiding = () => {
    if (dataModal) {
      setIsOpenModalSubsiding(true);
    } else {
      toast.error("Что то пошло ни так, ошибка данных");
    }
  };

  const [isOpenModalTradeIn, setIsOpenModalTradeIn] = useState(false);

  const handleChangeModalTradeIn = (value: boolean) => {
    setIsOpenModalTradeIn(value);
  };

  const handleClickTradeIn = () => {
    if (dataModal) {
      setIsOpenModalTradeIn(true);
    } else {
      toast.error("Что то пошло ни так, ошибка данных");
    }
  };

  return (
    <div className={style.by}>
      <div className={style.by__container}>
        <div className={style.by__dotedList}>
          <DottedList
            items={[
              { label: "Стоимость объекта", value: averageMarketPrice },
              {
                label: "Ставка по ипотеке",
                value: toPercent(mortgageInfo.percent),
              },
              {
                label: "Срок кридитования",
                value: mortgageInfo.periodMonth + " мес.",
              },
            ]}
          />
        </div>

        <div className={style.by__table}>
          <table width={"100%"}>
            <thead>
              <tr>
                <th colSpan={2}>Первоночальный взнос</th>
                <th>Ежемесячный платеж</th>
              </tr>
            </thead>
            <tbody>
              {mortgageInfo.calculations.map((calculation) => {
                return (
                  <tr>
                    <td>{toPercent(calculation.initialPaymentPercent)}</td>
                    <td>{toReadablePrice(calculation.initialPayment)}</td>
                    <td>{toReadablePrice(calculation.monthlyPayment)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className={style.by__buttons}>
          <Button
            label="Субсидировать"
            variant="dark"
            onClick={handleClickSubsiding}
          />
          <Button label="Trade-in" onClick={handleClickTradeIn} />
        </div>
        <div className={style.by__accompanimentReit}>
          <AccompanimentReitEstimate />
        </div>
      </div>
      {dataModal && (
        <>
          <ModalSubsidizing
            data={dataModal}
            onDismiss={handleChangeModalSubsiding}
            isOpen={isOpenModalSubsiding}
          />
          <ModalTradeIn
            data={dataModal}
            onDismiss={handleChangeModalTradeIn}
            isOpen={isOpenModalTradeIn}
            type="by"
            isSecondary={isSecondary}
          />
        </>
      )}
    </div>
  );
};

export default ByEstimate;
