import { useEffect, useState } from "react";
import { IDataModal } from "../../..";
import { ComplexAnimatedModal } from "../../../../../ui/modal/ComplexAnimatedModal";
import FormRequestExtended from "../../forms/formRequest/formRequestExtended";
import styles from "./styles.module.scss";
import useAuthUserOtpVerify from "../../../../../../api/hooks/use-auth-user-otp-verify";
import { toast } from "react-toastify";
import useAuthUserPatch from "../../../../../../api/hooks/use-auth-patch-user";
import useTradeInRansom, {
  ITradeIntRansomRequest,
} from "../../../../../../api/hooks/use-trade-in-ransom";
import { transformPhone } from "../../../../../../lib/utils";

interface IModalTradeInProps {
  isOpen: boolean;
  data: IDataModal;
  isSecondary: boolean;
  onDismiss: (value: boolean) => void;
}
export interface IDataBy {
  numberPhone: string;
  otp: string;
  firstName: string;
  lastName: string;
  patronymic?: string;
  
  userApartmentNumber: string;

  address: string;
  apartmentType?: string;
  areaInSqM: string;
}

const TEXT = `Компания Reit купит вашу квартиру в минимальный срок. Оставьте заявку, чтобы узнать на какую сумму за продажу вашей квартиры вы можете рассчитывать уже сегодня.
Укажите номер квартиры и оставьте заявку. Наш эксперт свяжется с вами для уточнения нюансов.`;

const ModalBy = ({
  isOpen,
  data,
  isSecondary,
  onDismiss,
}: IModalTradeInProps) => {
  const initDataBy = {
    numberPhone: "",
    otp: "",
    firstName: "",
    lastName: "",
    patronymic: "",

    userApartmentNumber: "",

    address: data.address,
    apartmentType: data.typeApartment,
    areaInSqM: data.areaInSqM,
  };

  const [dataBy, setDataBy] = useState<IDataBy>(initDataBy);

  const onChangeData = (value: string, key: string) => {
    setDataBy((prev) => ({ ...prev, [key]: value }));
  };

  const {
    error: authUserOtpVerifyError,
    isMutating: authUserOtpVerifyIsMutating,
    trigger: authUserOtpVerifyTrigger,
  } = useAuthUserOtpVerify();

  const {
    error: authUserPatchError,
    isMutating: authUserPatchIsMutating,
    trigger: authUserPatchTrigger,
  } = useAuthUserPatch();

  const {
    error: tradeInRansomError,
    isMutating: tradeInRansomIsMutating,
    trigger: tradeInRansomTrigger,
  } = useTradeInRansom();

  useEffect(() => {
    setDataBy(initDataBy);
  }, [isOpen]);

  const getSentData = () => {
    let sentData: ITradeIntRansomRequest = {
      address: dataBy.address,
      apartmentNumber: Number(dataBy.userApartmentNumber),
      square: Number(data.areaInSqM),
    };

    if (!isSecondary) {
      sentData = { ...sentData, apartmentTypeId: data.typeApartmentId };
    }
    return sentData;
  };

  const handleSubmit = async () => {
    try {
      await authUserOtpVerifyTrigger({
        phone: transformPhone(dataBy.numberPhone),
        otpCode: dataBy.otp,
      });
      await authUserPatchTrigger({
        lastName: dataBy.lastName,
        firstName: dataBy.firstName,
        patronymic: dataBy.patronymic,
      });

      await tradeInRansomTrigger(getSentData());

      toast.success(
        "Ваша заявка принята, наш эксперт свяжется с вами в течении 2х часов в рабочее время."
      );
      onDismiss(false)
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <ComplexAnimatedModal opened={isOpen} onClose={() => onDismiss(false)}>
      <div className={styles.commonModalContainer}>
        <FormRequestExtended
          title="Выкуп"
          text={TEXT}
          buttonLabel="Оставить заявку на выкуп"
          titleForPatForm={"Квартира выкупа:"}
          onClick={handleSubmit}
          type="sell"
          labelForApartment="Квартира:"
          data={dataBy}
          onChange={onChangeData}
          isLoading={authUserOtpVerifyIsMutating}
        />
      </div>
    </ComplexAnimatedModal>
  );
};
export default ModalBy;
