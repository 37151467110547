import useSWR from "swr";
import fetcher from "../../lib/fetcher";
import { ISelectItem } from "../../components/ui/select";

export type ITypesBuildingByComplexResponse = {
  id: number;
  fullTitle: string;
}[];

type ITypesBuildingByComplexResponseWrapper = {
  data: ITypesBuildingByComplexResponse;
};

const useTypesBuildingByComplexId = (
  id?: number | null,
  isTransformForSelect?: boolean
) => {
  const { data, error, isLoading } = useSWR(
    id ? `/building/${id}/project-types` : null,
    (url) =>
      id ? fetcher.swr<ITypesBuildingByComplexResponseWrapper>(url) : null,
    { errorRetryCount: 0, revalidateOnFocus: false }
  );

  const transformData = (
    dataRes: ITypesBuildingByComplexResponseWrapper | undefined | null
  ): ISelectItem[] => {
    if (dataRes) {
      return dataRes.data.map((item) => ({
        key: item.id,
        label: item.fullTitle,
      }));
    }
    return [];
  };

  return {
    types: isTransformForSelect ? transformData(data) : data ?? [],
    error,
    isLoading,
  };
};

export default useTypesBuildingByComplexId;
