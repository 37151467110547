import { useState } from "react";
import DottedList from "../../../../ui/dottedList";
import ImageCarousel, { CarouselImage } from "../../../../ui/carousel";
import Button from "../../../../ui/button";
import { ISecondaryFacilityRecommendationsResponse } from "../../interfaces";
import { IPrimaryFacilityRecommendationsResponse } from "../../interfaces";
import ModalAnalogsEstimate from "../modals/modalAnalogs/modalAnalogsEstimate";

import clsx from "clsx";
import style from "../aboutObject/styles.module.scss";

interface IAboutObjectEstimatesProps {
  recommendations:
    | ISecondaryFacilityRecommendationsResponse
    | IPrimaryFacilityRecommendationsResponse;
  sameFacilityCount: number;
  maxFacilityPrice: string;
  marketPrice: string;
  minFacilityPrice: string;
  floorCount: number;
  wallMaterial: string;
  commissioningYear: string;
  address: string;
  images?: CarouselImage[];
  isSecondary: boolean;
}

const AboutObjectEstimate = ({
  recommendations,
  sameFacilityCount,
  maxFacilityPrice,
  minFacilityPrice,
  marketPrice,
  floorCount,
  wallMaterial,
  commissioningYear,
  address,
  images,
  isSecondary,
}: IAboutObjectEstimatesProps) => {
  const [isOpenModalAnalog, setIsOpenModalAnalog] = useState(false);

  const handleToggleModal = () => {
    setIsOpenModalAnalog((prev) => !prev);
  };

  const isRecommendations =
    Object.keys(recommendations).filter((key) => {
      if ("lowestPricePerSqM" in recommendations) {
        return (
          recommendations[
            key as keyof ISecondaryFacilityRecommendationsResponse
          ] !== null
        );
      }
      return (
        recommendations[
          key as keyof IPrimaryFacilityRecommendationsResponse
        ] !== null
      );
    }).length > 0;

  const renderRangeMarketPrice = () => {
    return (
      <div className={style.marketPrice__container}>
        <h4 className={style.marketPrice__title}>Коридор рыночной стоимости</h4>

        <div className={style.marketPrice__row}>
          <div className={style.marketPrice__start}>
            <div className={style.marketPrice__startText}>
              <span>от</span>
              <span>{` ${minFacilityPrice}`}</span>
            </div>
          </div>

          <div className={style.marketPrice__mid}>
            <div className={style.marketPrice__midContainer}>
              <div className={style.marketPrice__midUp} />

              <div className={style.marketPrice__midText}>
                <span>{`${marketPrice}`}</span>
              </div>
            </div>
          </div>

          <div className={style.marketPrice__end}>
            <div className={style.marketPrice__endText}>
              <span>до</span>
              <span>{` ${maxFacilityPrice}`}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={style.aboutObject}>
      <div className={style.aboutObject__container}>
        <div className={style.aboutObject__card}>
          <div className={style.aboutObject__title}>
            <h4>Информация по квартире:</h4>
          </div>

          <div
            className={clsx(style.aboutObject__marketPrice, style.marketPrice)}
          >
            {renderRangeMarketPrice()}

            <div className={style.aboutObject__about}>
              <div className={style.aboutObject__dottedList}>
                <DottedList
                  items={[
                    { label: "Адрес", value: address },
                    { label: "Материал стен", value: wallMaterial },
                    { label: "Год постройки", value: commissioningYear },
                    { label: "Этажность", value: floorCount },
                  ]}
                />
              </div>
            </div>

            {!!images?.length && (
              <div className={style.aboutObject__imageCarousel}>
                <ImageCarousel images={images} className={style.carousel} />
              </div>
            )}
            {isRecommendations && (
              <div className={style.aboutObject__countAnalog}>
                <div>
                  <span style={{ fontSize: 14 }}>
                    <i>{`Всего схожих квартир ${
                      isSecondary ? "рядом" : ""
                    } в продаже:`}</i>
                  </span>
                  <span
                    style={{ fontSize: 16 }}
                  >{` ${sameFacilityCount}`}</span>
                  <div className={style.aboutObject__button}>
                    <Button
                      variant={"dark"}
                      label="Показать схожие квартиры"
                      onClick={handleToggleModal}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalAnalogsEstimate
        isSecondary={isSecondary}
        isOpen={isOpenModalAnalog}
        onDismiss={handleToggleModal}
        listAnalogs={recommendations}
        countAnalog={sameFacilityCount}
      />
    </div>
  );
};

export default AboutObjectEstimate;
