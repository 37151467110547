import style from "./styles.module.scss";
import clsx from "clsx";
import { Link } from "react-router-dom";
import DottedList from "../../../../../ui/dottedList";
import { SQ_METER_SIGN } from "../../../../../../lib/constants";
import { toReadablePrice } from "../../../../../../lib/utils";
import {
  IFacilityRecommendation,
  ISecondaryFacilityRecommendationsResponse,
} from "../../../interfaces/index";
import { IPrimaryFacilityRecommendationsResponse } from "../../../interfaces";
import { ComplexAnimatedModal } from "../../../../../ui/modal/ComplexAnimatedModal";
import ModalCarousel from "../../../../../ui/modal/CarouselModal";
import { useState } from "react";

const IMG_BASE = "../../../../../../../img/baseImg.jpg";
const NOT_FOUND_IMG = "../../../../../../../img/notfoundPlan.jpg";

const ICON_LESS_SQUARE = "../../../../../../../icons/less-square.svg";
const ICON_ROOM_PLUS = "../../../../../../../icons/more-rooms.svg";
const ICON_ALTERNATIVE_OBJECT = "../../../../../../../icons/alternative-object.svg";
const ICON_PLAN_ANOTHER = "../../../../../../../icons/another-plan.svg";

const ICON_PRICE_DOWN = "../../../../../../../icons/low-price.svg";
const ICON_PRICE_UP = "../../../../../../../icons/height-price.svg";
const ICON_PRICE_DOWN_SQM = "../../../../../../../icons/low-price-sqm.svg";
const ICON_PRICE_UP_SQM = "../../../../../../../icons/height-price-sqm.svg";
interface IModalAnalogsEstimateProps {
  isOpen: boolean;
  listAnalogs:
    | ISecondaryFacilityRecommendationsResponse
    | IPrimaryFacilityRecommendationsResponse;
  countAnalog: number;
  isSecondary: boolean;
  onDismiss: (value: boolean) => void;
}
interface IFacilityRecommendationExpand extends IFacilityRecommendation {
  badges: { name: string; icon: string }[];
}

const ModalAnalogsEstimate = ({
  isOpen,
  listAnalogs,
  countAnalog,
  isSecondary,
  onDismiss,
}: IModalAnalogsEstimateProps) => {
  const [isOpenImg, setIsOpenImg] = useState(false);

  const handleCloseImg = () => {
    setIsOpenImg(false);
  };

  const transformAnalogs = () => {
    const filterUndefined = (data: any) => {
      return data?.filter((item: any) => {
        return item !== undefined;
      });
    };

    const filterNullAnalogList = (data: string[]) => {
      if ("lowestPricePerSqM" in listAnalogs) {
        return data?.filter(
          (item) =>
            listAnalogs[item as keyof ISecondaryFacilityRecommendationsResponse]
        );
      }
      return data?.filter(
        (item) =>
          listAnalogs[item as keyof IPrimaryFacilityRecommendationsResponse]
      );
    };

    const filterDuplicate = (
      data: IFacilityRecommendationExpand[],
      isMergeBadge?: boolean
    ) => {
      const mergeBadges = isMergeBadge
        ? data.map((item, index) => {
            const sameItem = data.find(
              (itemSame, currentIndex) =>
                item?.title === itemSame?.title && index !== currentIndex
            );

            if (sameItem) {
              const badges = [...item.badges, ...sameItem?.badges].filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.icon === value.icon)
              );

              const newItem = {
                ...item,
                badges: badges,
              };
              return newItem;
            }

            return item;
          })
        : data;

      return mergeBadges?.filter(
        (item1, i) =>
          mergeBadges.findIndex((item2) => item2?.title === item1?.title) === i
      );
    };

    if ("lowestPricePerSqM" in listAnalogs) {
      const getTransform = () => {
        const keyListAnalogs = Object.keys(listAnalogs);
        const keyListAnalogsFiltered = filterNullAnalogList(keyListAnalogs);

        const isSameLowPrice =
          listAnalogs.lowestPrice?.title ===
          listAnalogs.lowestPricePerSqM?.title;

        const isSameHeightPrice =
          listAnalogs.highestPrice?.title ===
          listAnalogs.highestPricePerSqM?.title;

        const getBadge = () => {
          return keyListAnalogsFiltered?.map((key) => {
            if (key === "lowestPricePerSqM" || key === "lowestPrice") {
              const base = {
                ...listAnalogs[key]!,
                badges: [{ name: `Самая низкая цена`, icon: ICON_PRICE_DOWN }],
              };

              if (isSameLowPrice) {
                return base;
              }

              if (key === "lowestPricePerSqM") {
                return {
                  ...listAnalogs[key]!,
                  badges: [
                    {
                      name: `Самая низкая цена за ${SQ_METER_SIGN}`,
                      icon: ICON_PRICE_DOWN_SQM,
                    },
                  ],
                };
              }

              return base;
            }
            if (key === "highestPricePerSqM" || key === "highestPrice") {
              const base = {
                ...listAnalogs[key]!,
                badges: [{ name: `Самая высокая цена`, icon: ICON_PRICE_UP }],
              };

              if (isSameHeightPrice) {
                return base;
              }

              if (key === "highestPricePerSqM") {
                return {
                  ...listAnalogs[key]!,
                  badges: [
                    {
                      name: `Самая высокая цена за ${SQ_METER_SIGN}`,
                      icon: ICON_PRICE_UP_SQM,
                    },
                  ],
                };
              }
              return base;
            }
          });
        };

        return filterDuplicate(filterUndefined(getBadge()), true);
      };
      const listAnalogsTransformed: IFacilityRecommendationExpand[] =
        getTransform();
      return listAnalogsTransformed;
    }

    const getTransform = () => {
      const keyListAnalogs = Object.keys(listAnalogs);
      const keyListAnalogsFiltered = filterNullAnalogList(keyListAnalogs);
      const getBadge = () => {
        return keyListAnalogsFiltered?.map((key) => {
          if (key === "similarByPriceAndByArea") {
            return {
              ...listAnalogs[key]!,
              badges: [{ name: "Альтернативный объект", icon: ICON_ALTERNATIVE_OBJECT }],
            };
          }

          if (key === "similarByPriceWithAnotherPlan") {
            return {
              ...listAnalogs[key]!,
              badges: [{ name: "Другая планировка", icon: ICON_PLAN_ANOTHER }],
            };
          }

          if (key === "highestPriceWithLessRoomCountAndArea") {
            return {
              ...listAnalogs[key]!,
              badges: [{ name: "Меньше площадь", icon: ICON_LESS_SQUARE }],
            };
          }

          if (key === "lowestPriceWithMoreRoomCountAndArea") {
            return {
              ...listAnalogs[key]!,
              badges: [{ name: "Больше комнат", icon: ICON_ROOM_PLUS }],
            };
          }
        });
      };
      return filterDuplicate(filterUndefined(getBadge()));
    };

    const listAnalogsTransformed: IFacilityRecommendationExpand[] =
      getTransform();
    return listAnalogsTransformed;
  };

  const getCard = (analog: IFacilityRecommendationExpand) => {
    return (
      <div className={clsx(style.modalAnalogs__card, style.card)}>
        <div className={style.card__container}>
          <div className={style.card__title}>
            <h3>{analog.title}</h3>
          </div>

          <div className={style.card__badges}>
            {analog?.badges?.map((badge) => {
              return (
                <div className={style.card__badge}>
                  <img src={badge.icon} width={32} height={32} alt="icon" />
                  <span>{badge.name}</span>
                </div>
              );
            })}
          </div>

          <div className={style.card__img}>
            {
              <img
                onClick={() => setIsOpenImg(true)}
                src={
                  analog?.imageUrl
                    ? analog?.imageUrl
                    : isSecondary
                    ? IMG_BASE
                    : NOT_FOUND_IMG
                }
                alt="изображение"
              />
            }
            {analog?.imageUrl && (
              <ModalCarousel
                opened={isOpenImg}
                onClose={handleCloseImg}
                images={[
                  {
                    src: analog?.imageUrl,
                    alt: "analog",
                  },
                ]}
              />
            )}
          </div>

          <div className={style.card__about}>
            <DottedList
              items={[
                { label: "Адрес", value: analog.shortAddress },
                { label: "Материал стен", value: analog.wallMaterial },
                {
                  label: `Цена за ${SQ_METER_SIGN}`,
                  value: `${toReadablePrice(analog.pricePerSqM)}`,
                },
              ]}
            />

            <div className={style.card__bottom}>
              <div className={style.card__resource}>
                {analog.sources?.length ? (
                  <u>
                    <Link to={analog?.sources[0].url} title="Источник">
                      {`Источник`}
                    </Link>
                  </u>
                ) : (
                  ""
                )}
              </div>

              <div className={style.card__price}>
                <span>{`${toReadablePrice(analog.currentPrice)}`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const genListAnalogs = () => {
    return (
      <div className={style.modalAnalogs__cards}>
        {transformAnalogs()?.map((analog) => getCard(analog))}
      </div>
    );
  };

  return (
    <ComplexAnimatedModal opened={isOpen} onClose={() => onDismiss(false)}>
      <div className={style.modalAnalogs}>
        <div className={style.modalAnalogs__container}>
          <div className={style.modalAnalogs__title}>
            <h2> Похожие квартиры в продаже </h2>

            {genListAnalogs()}

            <div className={style.modalAnalogs__countAnalog}>
              Всего схожих квартир рядом в продаже:
              <span>{` ${countAnalog}`}</span>
            </div>
          </div>
        </div>
      </div>
    </ComplexAnimatedModal>
  );
};

export default ModalAnalogsEstimate;
