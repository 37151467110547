import useSWR from "swr";
import fetcher from "../../lib/fetcher";
import { APIApartmentComplexBuilding } from "../interfaces";
import { ISelectItem } from "../../components/ui/select";
import { sortByTitle } from "../../lib/utils";

export type TComplexesResponse = {
  id: number;
  title: string;
  buildings: APIApartmentComplexBuilding[];
};

export type APIApartmentComplexSearchResponseWrapper = {
  data: {
    complexes: TComplexesResponse[];
  };
};

const useApartmentComplexSearch = (
  isTransformForSelect?: boolean,
  isFetch: boolean = true
) => {
  const { data, error, isLoading } =
    useSWR<APIApartmentComplexSearchResponseWrapper>(
      `/apartment-complex/search?titleSubstring=`,
      isFetch ? fetcher.swr : null
    );

  const transformData = (
    dataRes: APIApartmentComplexSearchResponseWrapper | undefined
  ): ISelectItem[] => {
    if (dataRes) {
      const dataTransformed = dataRes.data?.complexes?.map((item) => ({
        key: item.id,
        label: item.title,
      }));
      
      return sortByTitle(dataTransformed) as ISelectItem[];
    }
    return [];
  };

  return {
    complexes: isTransformForSelect
      ? transformData(data)
      : data?.data.complexes ?? [],
    error,
    isLoading,
  };
};

export default useApartmentComplexSearch;
