import fetcher from "../../lib/fetcher";
import useSWR from "swr";

interface IApartmentBuildingAreaMinMaxResponse {
  min: number;
  max: number;
}

interface IApartmentBuildingAreaMinMaxResponseWrapper {
  data: IApartmentBuildingAreaMinMaxResponse;
}

const useApartmentBuildingAreaMinMax = (
  id?: number | null,
  type?: number | null
) => {
  const { data, error, isLoading, mutate } = useSWR(
    id && type ? `/building/${id}/available-area-limits` : null,
    (url) =>
      id && type
        ? fetcher.swr<IApartmentBuildingAreaMinMaxResponseWrapper>(url, {
            params: { typeId: type },
          })
        : null,
    { errorRetryCount: 0, revalidateOnFocus: false }
  );

  return { minMaxArea: data?.data, error, isLoading, mutate };
};

export default useApartmentBuildingAreaMinMax;
