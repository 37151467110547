import { useEffect } from 'react';
import { useDataEstimateContext } from '../../../../hooks/useModalContex'; 

interface IResetor {
  handleResetStreet: () => void;
  handleResetBuilding: () => void;
  handleResetComplex: () => void;
  handleResetBuildingComplex: () => void;
  handleResetTypesApartment: () => void;
  handleResetArea: () => void;
}

const useReset = (
  isSecondarySwitcher: boolean,
  resetor: IResetor,
  selectedStreet: number | null,
  selectedComplex: number | null,
  selectedBuildingSecondary: number | null,
  selectedBuildingPrimary: number | null,
  selectedTypesApartment: number | null,
) => {
  const { onSetDataModal } = useDataEstimateContext();
  const resetValues = [
    'street',
    'building',
    'complex',
    'buildingComplex',
    'typesApartment',
    'area',
  ];

  type TResetValues = typeof resetValues;

  useEffect(() => {
    onSetDataModal(null);
    handleResetDataChoice(resetValues);
  }, [isSecondarySwitcher]);

  const handleResetDataChoice = (resetValues: TResetValues) => {
    if (isSecondarySwitcher) {
      resetValues.includes('street') && resetor.handleResetStreet();
      resetValues.includes('building') && resetor.handleResetBuilding();
    } else {
      resetValues.includes('complex') && resetor.handleResetComplex();
      resetValues.includes('buildingComplex') && resetor.handleResetBuildingComplex();
    }
    resetValues.includes('typesApartment') && resetor.handleResetTypesApartment();
    resetValues.includes('area') && resetor.handleResetArea();
  };

  useEffect(() => {
    if (selectedStreet || selectedComplex) {
      handleResetDataChoice(['building', 'buildingComplex', 'typesApartment', 'area']);
    }
  }, [selectedStreet, selectedComplex]);

  useEffect(() => {
    if (selectedBuildingSecondary || selectedBuildingPrimary) {
      handleResetDataChoice(['typesApartment', 'area']);
    }
  }, [selectedBuildingSecondary, selectedBuildingPrimary]);

  useEffect(() => {
    if (selectedTypesApartment) {
      handleResetDataChoice(['area']);
    }
  }, [selectedTypesApartment]);

  return {};
};

export default useReset;
