import {
  IEstimatePrimaryRequest,
  IPrimaryFacilityEstimateResponseWrapper,
} from "../../components/pages/estimate/interfaces";
import fetcher from "../../lib/fetcher";
import useSWRMutation from "swr/mutation";

const LAST_MESSAGE =
  "Произошла ошибка. Возможно введены не верные данные, проверьте форму. Оставьте заявку, отчет будет подготовлен нашим экспертом.";

const useEstimatePrimary = () => {
  async function fetchEstimateSPrimary(
    url: string,
    { arg }: { arg: IEstimatePrimaryRequest }
  ) {
    try {
      const data = await fetcher.post(url, arg);

      if (error?.data) {
        throw LAST_MESSAGE;
      }

      return data.result as IPrimaryFacilityEstimateResponseWrapper;
    } catch (error) {
      throw LAST_MESSAGE;
    }
  }

  const { data, error, isMutating, trigger } = useSWRMutation(
    "/facility/estimate-primary",
    fetchEstimateSPrimary
  );

  return { data: data?.data, error, isMutating, trigger };
};
export default useEstimatePrimary;
