import { YMaps, Map } from "@pbe/react-yandex-maps";
import YMapPlacemark from "../placemarkYmap";

import styles from "./styles.module.scss";

export type MapItem = {
  id: number;
  selected: boolean;
  coords: [number, number];
};

type ItemsYmapProps = {
  items: MapItem[];
  onItemClick?: (item: MapItem) => void;
  zoom?: number;
  isSetCenterByFirstItem?: boolean;
};

const KRSK_COORDS = [56.023097, 92.874172];

const ItemsYmap: React.FC<ItemsYmapProps> = (props) => {
  const { items, zoom, isSetCenterByFirstItem, onItemClick } = props;

  return (
    <YMaps>
      <Map
        modules={["control.ZoomControl"]}
        className={styles.yandexMap}
        state={{
          center: isSetCenterByFirstItem ? items[0].coords : KRSK_COORDS,
          zoom: zoom ? zoom : 11,
          controls: ["zoomControl"],
          behaviors: ["disable('scrollZoom')"],
        }}
      >
        {items.map((i, idx) => (
          <YMapPlacemark
            key={idx}
            selected={i.selected}
            defaultGeometry={i.coords}
            onClick={() => onItemClick && onItemClick(i)}
          />
        ))}
      </Map>
    </YMaps>
  );
};

export default ItemsYmap;
