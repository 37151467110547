import useSWR from "swr";
import fetcher from "../../lib/fetcher";
import { ISelectItem } from "../../components/ui/select";
import { sortByTitle } from "../../lib/utils";

export type IStreetByBuildingResponse = {
  name: string;
  id: number;
}[];

type IStreetByBuildingResponseWrapper = {
  data: IStreetByBuildingResponse;
};

export const useStreetList = (
  isTransformForSelect?: boolean,
  isFetch: boolean = true
) => {
  const { data, error, isLoading, mutate } =
    useSWR<IStreetByBuildingResponseWrapper>(
      "/building/street-by-building",
      isFetch ? fetcher.swr : null,
      {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 0,
      }
    );

  const transformData = (
    dataRes: IStreetByBuildingResponseWrapper | undefined
  ): ISelectItem[] => {
    if (dataRes) {
      const dataTransformed = dataRes.data.map((item) => ({
        key: item.id,
        label: item.name,
      }));
      return sortByTitle(dataTransformed) as ISelectItem[];
    }
    return [];
  };

  return {
    streets: isTransformForSelect ? transformData(data) : data ? data : [],
    error,
    isLoading,
    mutate,
  };
};

export default useStreetList;
