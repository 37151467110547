import clsx from "clsx";
import styles from "./styles.module.scss";

export type BaseCheckboxProps = {
  checked: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  inputClassName?: string;
  markClassName?: string;
  label?: React.ReactNode;
  labelPosition?: "left" | "right" | "center";
  labelClassName?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  isDisabled?: boolean;
};

const BaseCheckbox: React.FC<BaseCheckboxProps> = (props) => {
  const {
    checked,
    onChange,
    label,
    labelPosition,
    className,
    inputClassName,
    markClassName,
    labelClassName,
    style,
    children,
    isDisabled,
  } = props;

  return (
    <label
      className={clsx(
        styles.container,
        labelPosition === "left" && styles.reverse,
        className
      )}
      style={style}
    >
      {label && (
        <span
          className={clsx(
            styles.label,
            labelPosition === "left" ? styles.labelLeft : styles.labelRight,
            labelClassName
          )}
        >
          {label}
        </span>
      )}

      <input
        type="checkbox"
        className={clsx(styles.input, inputClassName)}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={isDisabled}
      />

      <span className={markClassName} />

      {children}
    </label>
  );
};

export default BaseCheckbox;
