import FormRequest from "../../forms/formRequest/formRequest";
import { IDataModal } from "../../..";
import { ComplexAnimatedModal } from "../../../../../ui/modal/ComplexAnimatedModal";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import useAuthUserOtpVerify from "../../../../../../api/hooks/use-auth-user-otp-verify";
import useAuthUserPatch from "../../../../../../api/hooks/use-auth-patch-user";
import useDealRequest, {
  EAddFacilityDealRequestType,
  EMarketType,
  IDealRequest,
} from "../../../../../../api/hooks/use-deal-request";
import { toast } from "react-toastify";
import { transformPhone } from "../../../../../../lib/utils";
interface IModalSubsidizingProps {
  isOpen: boolean;
  data: IDataModal;
  onDismiss: (value: boolean) => void;
}
export interface IDataSubsidizing {
  numberPhone: string;
  otp: string;
  firstName: string;
  lastName: string;
  patronymic?: string;

  address: string;
  apartmentType?: string;
  areaInSqM: string;
}

const TEXT = `Поможем снизить ставку по ипотеке. Важно: стоимость квартиры может измениться.
Оставьте свои контакты и наш эксперт свяжется с вами и расскажет все нюансы.`;

const ModalSubsidizing = ({
  isOpen,
  data,
  onDismiss,
}: IModalSubsidizingProps) => {
  const initDataSubsidizing = {
    numberPhone: "",
    otp: "",
    firstName: "",
    lastName: "",
    patronymic: "",

    address: data.address,
    apartmentType: data.typeApartment,
    areaInSqM: data.areaInSqM,
  };

  const [dataSubsidizing, setDataSubsidizing] =
    useState<IDataSubsidizing>(initDataSubsidizing);

  const {
    error: errorDealRequest,
    isMutating: isMutatingDealRequest,
    trigger: triggerDealRequest,
  } = useDealRequest();

  const onChangeData = (value: string, key: string) => {
    setDataSubsidizing((prev) => ({ ...prev, [key]: value }));
  };

  const {
    error: authUserOtpVerifyError,
    isMutating: authUserOtpVerifyIsMutating,
    trigger: authUserOtpVerifyTrigger,
  } = useAuthUserOtpVerify();

  const {
    error: authUserPatchError,
    isMutating: authUserPatchIsMutating,
    trigger: authUserPatchTrigger,
  } = useAuthUserPatch();

  useEffect(() => {
    setDataSubsidizing(initDataSubsidizing);
  }, [isOpen]);

  const getSentData = () => {
    let dataSent: IDealRequest = {
      address: data.address,
      square: Number(data.areaInSqM),
      dealRequestType : EAddFacilityDealRequestType.SubsidiesNoSystem,
      marketType: EMarketType.Primary,
    };

    if (data.typeApartmentId) {
      dataSent = { ...dataSent, apartmentTypeId: data.typeApartmentId };
    }

    return dataSent;
  };

  const handleSubmit = async () => {
    try {
      await authUserOtpVerifyTrigger({
        phone: transformPhone(dataSubsidizing.numberPhone),
        otpCode: dataSubsidizing.otp,
      });

      await authUserPatchTrigger({
        lastName: dataSubsidizing.lastName,
        firstName: dataSubsidizing.firstName,
        patronymic: dataSubsidizing.patronymic,
      });

      await triggerDealRequest(getSentData());

      toast.success(
        "Ваша заявка принята, наш эксперт свяжется с вами в течении 2х часов в рабочее время."
      );

      onDismiss(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ComplexAnimatedModal opened={isOpen} onClose={() => onDismiss(false)}>
      <div className={styles.commonModalContainer}>
        <FormRequest
          title="Субсидирование"
          text={TEXT}
          data={dataSubsidizing}
          buttonLabel="Оставить заявку на субсидирование"
          onClick={handleSubmit}
          onChange={onChangeData}
          isLoading={authUserOtpVerifyIsMutating}
        />
      </div>
    </ComplexAnimatedModal>
  );
};

export default ModalSubsidizing;
