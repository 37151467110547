import { IInputProps, Input } from ".";

interface InputNumberProps extends IInputProps {
  onChange: (value: string) => void;
  length?: number;
}

const InputNumber = ({ onChange, length, ...other }: InputNumberProps) => {
  const handleChange = (value: string) => {
    if (value === "") {
      return onChange("");
    }
    const isNumber = /^\d+$/;

    if (isNumber.test(value)) {
      if (length) {
        if (value.length <= length) {
          return onChange(value);
        }
        return;
      }

      return onChange(value);
    }
  };

  return <Input onChange={handleChange} {...other} />;
};

export default InputNumber;
