import { toast } from "react-toastify";
import fetcher from "../../lib/fetcher";
import useSWRMutation from "swr/mutation";

const useAuthUserNumberPhone = () => {
  async function authUserNumberPhone(
    url: string,
    { arg }: { arg: { phone: string } }
  ) {
    try {
      const data = await fetcher.post(url, arg);
      return data.result;
    } catch (error: any) {
      toast.error(`Что то не так. Проверьте номер. Причина: ${error}`);
      throw new Error(error);
    }
  }

  const { data, error, isMutating, trigger } = useSWRMutation(
    "/auth/sign-in",
    authUserNumberPhone
  );

  return { data: data, error, isMutating, trigger };
};
export default useAuthUserNumberPhone;
