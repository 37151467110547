import { RUBLE_SIGN } from "./constants";

export const toReadableNumber = (num: number | string, delim = " ") => {
  return num
    ?.toString()
    .split("")
    .reverse()
    .reduce((acc, curr, i) => {
      if (i !== 0 && i % 3 === 0) {
        return curr + delim + acc;
      }

      return curr + acc;
    }, "");
};

export const toReadablePrice = (num: number | string) => {
  return toReadableNumber(num) + " " + RUBLE_SIGN;
};

export const toPercent = (num: number) => {
  return `${num} %`;
};

export const transformPhone = (phone: string) => {
  return phone.replaceAll(" ", "");
};

interface ISortInterfaceByTitle {
  label: string;
  [key: string]: any;
}

export const sortByTitle = (arr: ISortInterfaceByTitle[]) => {
  return arr.sort(customSort);

  function customSort(a: ISortInterfaceByTitle, b: ISortInterfaceByTitle) {
    const titleA = a.label;
    const titleB = b.label;

    // Паттерн для проверки, начинается ли строка с цифры
    const isNumericA = /^\d/.test(titleA);
    const isNumericB = /^\d/.test(titleB);

    // Если оба заголовка являются строками, начинающимися с букв
    if (!isNumericA && !isNumericB) {
      return titleA.localeCompare(titleB, "ru");
    }

    // Если один заголовок строка, а другой - число
    if (isNumericA && !isNumericB) {
      return 1; // Числа идут после строк
    }
    if (!isNumericA && isNumericB) {
      return -1; // Строки идут перед числами
    }

    // Если оба заголовка - числа, сравниваем их как числа
    const numA = titleA.match(/^\d+/)
      ? parseInt(titleA.match(/^\d+/)![0])
      : titleA;
    const numB = titleB.match(/^\d+/)
      ? parseInt(titleB.match(/^\d+/)![0])
      : titleB;

    if (numA !== numB) {
      return Number(numA) - Number(numB);
    }

    // Если числа равны, сортируем по полному заголовку
    return titleA.localeCompare(titleB, "ru");
  }
};
