import { toast } from "react-toastify";
import fetcher from "../../lib/fetcher";
import useSWRMutation from "swr/mutation";

export enum EAddFacilityDealRequestType {
  SubsidiesNoSystem = "subsidies-no-system",
  ServiceDealNoSystem = "service-deal-no-system",
  ErrorCalculation = "error-calculation",
}

export enum EMarketType {
  Primary = "Primary",
  Secondary = "Secondary",
}

export interface IDealRequest {
  address: string;
  apartmentTypeId?: number;
  square: number;
  dealRequestType: EAddFacilityDealRequestType;
  marketType: EMarketType;
}

const useDealRequest = () => {
  async function dealRequest(url: string, { arg }: { arg: IDealRequest }) {
    try {
      const data = await fetcher.post(url, arg);
      return data.result;
    } catch (error: any) {
      toast.error(
        `Что то не так. Проверьте заполненные данные. Причина: ${error}`
      );
      throw new Error(error);
    }
  }

  const { data, error, isMutating, trigger } = useSWRMutation(
    "/deal-requests/in-not-system",
    dealRequest
  );

  return { data: data, error, isMutating, trigger };
};

export default useDealRequest;
