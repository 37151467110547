import clsx from "clsx";
import Switchbox from ".";
import style from "./stylesSwitchBoxWithItems.module.scss";

interface ISwitchBoxWithItemsProps {
  onChangeSwitcher: (value: boolean) => void;
  isChecked: boolean;
  classNameSwitcher?: ".slider_estimate";
}

const SwitchBoxWithItems = ({
  isChecked,
  classNameSwitcher,
  onChangeSwitcher,
}: ISwitchBoxWithItemsProps) => {
  return (
    <div className={style.switcher}>
      <div className={style.switcher__container}>
        <div
          className={clsx(
            style.switcher__item,
            isChecked && style.switcher__item_gray,
            !isChecked && style.switcher__item_line
          )}
          onClick={() => onChangeSwitcher(false)}
        >
          <h3>Хочу купить</h3>
        </div>

        <div className={clsx(style.switcher__switcher)}>
          <div>
            <Switchbox
              checked={isChecked}
              onChange={onChangeSwitcher}
              className={classNameSwitcher}
            />
          </div>
        </div>

        <div
          className={clsx(
            style.switcher__item,
            classNameSwitcher === ".slider_estimate" &&
              style.switcher__item_green,
            !isChecked && style.switcher__item_gray,
            isChecked && style.switcher__item_line
          )}
          onClick={() => onChangeSwitcher(true)}
        >
          <h3>Хочу продать</h3>
        </div>
      </div>
    </div>
  );
};

export default SwitchBoxWithItems;
