import { Input } from "../../../../../ui/input";
import style from "./styles.module.scss";
import InputSQMeter from "../../../../../ui/input/input-sq-meter";
import Button from "../../../../../ui/button";
import { CheckBox } from "../../../../../ui/checkbox/checkboxMain";
import { Link } from "react-router-dom";
import { IDataSubsidizing } from "../../modals/modalAnalogs/modalSubsidizing";
import { useState } from "react";
import { IDataSupport } from "../../modals/modalAnalogs/modalSupportDeal";
import UserForm from "./formRequestUser";
type IDataFormRequest = IDataSubsidizing | IDataSupport;

interface IFormRequestProps {
  title: string;
  text: string;
  data: IDataFormRequest;
  buttonLabel: string;
  isLoading: boolean;
  onClick: () => void;
  onChange: (value: string, key: string) => void;
}

const FormRequest = ({
  title,
  text,
  data,
  buttonLabel,
  isLoading,
  onClick,
  onChange,
}: IFormRequestProps) => {
  const [isCheck, setIsCheck] = useState(false);

  const disabled = !(
    isCheck &&
    data.firstName &&
    data.lastName &&
    data.numberPhone &&
    data.otp
  );

  return (
    <div className={style.formRequest}>
      <div className={style.formRequest__container}>
        <div className={style.formRequest__title}>
          <h3>{title}</h3>
        </div>
        <div className={style.formRequest__text}>
          <p>{text}</p>
        </div>

        <form
          className={style.formRequest__form}
          onSubmit={(e) => e.preventDefault()}
        >
          <h4>Ваши данные:</h4>
          <UserForm
            data={{
              numberPhone: data?.numberPhone,
              otp: data?.otp,
              firstName: data?.firstName,
              lastName: data?.lastName,
              patronymic: data?.patronymic,
            }}
            onChange={onChange}
          />

          <div>
            <h4>Квартира:</h4>
          </div>
          <div className={style.formRequest__address}>
            <Input value={data.address} label="Адрес квартиры" readOnly />
          </div>
          {data.apartmentType && (
            <div className={style.formRequest__apartmentType}>
              <Input value={data.apartmentType} label="Тип квартиры" readOnly />
            </div>
          )}
          <div className={style.formRequest__areaInSqM}>
            <InputSQMeter value={data.areaInSqM} readOnly />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckBox
              checked={isCheck}
              onChange={(value) => setIsCheck(value)}
            />
            <div style={{ marginTop: 18 }}>
              <span>Отправляя заявку я соглашаюсь с </span>
              <Link
                target={"_blank"}
                to={"https://reit-invest.ru/privacy_policy"}
              >
                политикой конфиденциальности
              </Link>
              <span> и </span>
              <Link target={"_blank"} to={"https://reit-invest.ru/consent"}>
                согласием на обработку персональных данных.
              </Link>
            </div>
          </div>
        </form>

        <div className={style.formRequest__button}>
          <Button
            label={buttonLabel}
            onClick={onClick}
            disabled={disabled}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default FormRequest;
