import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { API_BASE_URL } from "../api/constans";
import { toast } from "react-toastify";
import localStorageServices from "./services/localStorage.service";

export type CustomAxiosReqConfig = AxiosRequestConfig & {
  errorToast?: boolean;
};
export type F<T> =
  | { result?: undefined; error: string }
  | { result: T; error?: undefined };

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

const fetcher = <T = any>(url: string, config?: CustomAxiosReqConfig) =>
  axiosInstance<never, T & { error?: string }>(url, config).then(
    (result): F<T> => {
      if (!result.error) {
        return { result };
      }

      const error =
        typeof result.error === "string" ? result.error : "Неизвестная ошибка";

      if (config?.errorToast) {
        toast.error(error);
      }

      return result as { error: string };
    }
  );

const createMethodFunc =
  (method: string) =>
  <T>(url: string, data?: any, config?: CustomAxiosReqConfig) =>
    fetcher<T>(url, { ...config, data, method });

fetcher.post = createMethodFunc("post");
fetcher.patch = createMethodFunc("patch");
fetcher.delete = createMethodFunc("delete");

const swrFetcher = <T = any>(url: string, config?: CustomAxiosReqConfig) =>
  axiosInstance<never, T & { error?: string }>(url, config).then((res) => {
    if (res === undefined) {
      throw "Ошибка соединения с сервером";
    }

    if (res.error) {
      throw res.error;
    }

    return res as T;
  });

fetcher.swr = swrFetcher;

const setUserDataAfterVerify = async (response: AxiosResponse<any, any>) => {
  await localStorageServices.removeAuthData();
  await localStorageServices.setTokens({
    accessToken: response?.data?.token,
  });
};

axiosInstance.interceptors.response.use(
  async function (response) {
    const token = response?.data?.token;
    if (
      response?.data?.token &&
      response.config.url === "/auth/verify-otp-code"
    ) {
      setUserDataAfterVerify(response);
    }
    return response;
  },

  function (error) {
    return Promise.reject(
      error?.response?.data?.message ?? "Непредвиденная ошибка."
    );
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers[
      "Authorization"
    ] = `Bearer ${localStorageServices.getAcesToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default fetcher;
