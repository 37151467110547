import { toast } from "react-toastify";
import fetcher from "../../lib/fetcher";
import useSWRMutation from "swr/mutation";

export interface ITradeIntMissingObjectRequest {
  address?: string;
  objectRedemption?: {
    address?: string;
    apartmentNumber?: number;
    apartmentTypeId?: number;
    square?: number;
  };
  apartmentTypeId?: number;
  square?: number;
  apartmentNumber?: number;
}

const useTradeIntMissingObject = () => {
  async function tradeInMissingObject(
    url: string,
    { arg }: { arg: ITradeIntMissingObjectRequest }
  ) {
    try {
      const data = await fetcher.post(url, arg);
      return data.result;
    } catch (error: any) {
      toast.error(
        `Что то не так. Проверьте заполненные данные. Причина: ${error}`
      );
      throw new Error(error);
    }
  }

  const { data, error, isMutating, trigger } = useSWRMutation(
    "/trade-in/missing-object",
    tradeInMissingObject
  );

  return { data: data, error, isMutating, trigger };
};
export default useTradeIntMissingObject;
