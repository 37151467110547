import { toast } from "react-toastify";
import fetcher from "../../lib/fetcher";
import useSWRMutation from "swr/mutation";

export interface ITradeIntRansomRequest {
  address: string;
  apartmentNumber: number;
  apartmentTypeId?: number;
  square: number;
}

const useTradeInRansom = () => {
  async function tardeInRansom(
    url: string,
    { arg }: { arg: ITradeIntRansomRequest }
  ) {
    try {
      const data = await fetcher.post(url, arg);
      return data.result;
    } catch (error: any) {
      toast.error(
        `Что то не так. Проверьте заполненные данные. Причина: ${error}`
      );
      throw new Error(error);
    }
  }

  const { data, error, isMutating, trigger } = useSWRMutation(
    "/trade-in/ransom",
    tardeInRansom
  );

  return { data: data, error, isMutating, trigger };
};
export default useTradeInRansom;
