import { createContext, useEffect, useRef, useState } from "react";
import HeaderEstimate from "./components/header/headerEstimate";
import FormEstimateMarketPrice from "./components/forms/formMarketPrice/formEstimateMarketPrice";
import AboutObjectEstimate from "./components/aboutObject/aboutObjectEstimate";
import PossibilitiesEstimate from "./components/possibilities/posibilitiesEstimate";
import style from "./styles.module.scss";
import { toReadablePrice } from "../../../lib/utils";
import {
  ISecondaryFacilityEstimateResponse,
  IPrimaryFacilityEstimateResponse,
} from "./interfaces";
import { RUBLE_SIGN } from "../../../lib/constants";

export interface ISecondaryFacilityEstimateResponseWithAddress
  extends ISecondaryFacilityEstimateResponse {
  address: string;
}
export interface IPrimaryFacilityEstimateResponseWithAddress
  extends IPrimaryFacilityEstimateResponse {
  address: string;
}
export interface IDataModal {
  areaInSqM: string;
  typeApartment?: string;
  typeApartmentId?: number;
  address: string;
}

interface IDataEstimateContext {
  dataModal: IDataModal | null;
  onSetDataModal: (data: IDataModal | null) => void;
}

export const DataEstimateContext = createContext<IDataEstimateContext | null>(
  null
);

const Estimate = () => {
  const [data, setData] = useState<
    | ISecondaryFacilityEstimateResponseWithAddress
    | IPrimaryFacilityEstimateResponseWithAddress
    | null
  >(null);

  const handleSetData = (
    data:
      | ISecondaryFacilityEstimateResponseWithAddress
      | IPrimaryFacilityEstimateResponseWithAddress
  ) => {
    setData(data);
  };

  const handleResetData = () => {
    setData(null);
  };

  const blockAppearsRef = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    const yOffset = -100;
    if (blockAppearsRef.current) {
      const y =
        blockAppearsRef.current?.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  const [isSecondarySwitcher, setSwitcher] = useState(false);
  const handleSwitch = (value: boolean) => {
    setSwitcher(value);
  };

  useEffect(() => {
    handleResetData();
  }, [isSecondarySwitcher]);

  const getYear = () => {
    if (data) {
      if ("commissioningYear" in data) {
        return data.commissioningYear;
      } else {
        if (data.commissioningDate) {
          return data.commissioningDate;
        } else {
          return data.commissioningQuarter;
        }
      }
    }
  };

  const getImages = () => {
    if (data && "plans" in data) {
      return data.plans.map((plan) => ({ src: plan.url, alt: plan.name }));
    }
  };

  const [dataModal, setDataModal] = useState<IDataModal | null>(null);
  const handleSetDataModal = (data: IDataModal | null) => {
    return setDataModal(data);
  };

  return (
    <section className={style.estimate}>
      <DataEstimateContext.Provider
        value={{
          dataModal,
          onSetDataModal: handleSetDataModal,
        }}
      >
        <div className={style.estimate__container}>
          <HeaderEstimate
            switcher={isSecondarySwitcher}
            onSwitch={handleSwitch}
          />
          <FormEstimateMarketPrice
            isSecondarySwitcher={isSecondarySwitcher}
            onCallBackData={handleSetData}
            resetData={handleResetData}
          />
          {data && (
            <div ref={blockAppearsRef}>
              <AboutObjectEstimate
                isSecondary={isSecondarySwitcher}
                maxFacilityPrice={
                  String(data.maxFacilityPrice)[0] +
                  "." +
                  String(data.maxFacilityPrice)[1] +
                  " " +
                  "млн" +
                  ` ${RUBLE_SIGN}`
                }
                minFacilityPrice={
                  String(data.minFacilityPrice)[0] +
                  "." +
                  String(data.minFacilityPrice)[1] +
                  " " +
                  "млн" +
                  ` ${RUBLE_SIGN}`
                }
                marketPrice={toReadablePrice(data.averageMarketPrice)}
                floorCount={data.floorCount}
                wallMaterial={data.wallMaterial}
                commissioningYear={String(getYear()) + " г"}
                recommendations={data.recommendations}
                sameFacilityCount={data.sameFacilityCount}
                address={data.address}
                images={getImages()}
              />
              <PossibilitiesEstimate
                averageMarketPrice={toReadablePrice(data.averageMarketPrice)}
                mortgageInfo={data.mortgageInfo}
                isSecondary={isSecondarySwitcher}
              />
            </div>
          )}
        </div>
      </DataEstimateContext.Provider>
    </section>
  );
};

export default Estimate;
