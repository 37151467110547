import clsx from "clsx";
import { cloneElement, useEffect, useMemo, useRef, useState } from "react";
import { Input } from "../input";
import { CheckBox } from "../checkbox/checkboxMain";
import { useOnClickOutside } from "../../../lib/hooks/useOnClickOutside";

import styles from "./styles.module.scss";

const plusIcon = "../../../../icons/icon-plus.svg";
const arrowDownIcon = "../../../../icons/icon-arrow-down.svg";

export type TSelectSize = "sm" | "md" | "lg";

export type TSelectValue = number | string | any;

export interface ISelectItem {
  key: TSelectValue;
  label: string | number;
  note?: string;
}

type ISelectGeneral = {
  size?: TSelectSize;
  label?: string;
  error?: string;
  search?: boolean;
  disabled?: boolean;
  menuArray: ISelectItem[];
  children?: JSX.Element;
  className?: string;
  openBottom?: boolean;
  placeholder?: string;
  addButtonText?: string;
  isMainPlaceholder?: boolean;
  addButtonOnClick?: () => void;
  searchPlaceholder?: string;
  isCheckBoxOnRightSide?: boolean;
};

export type ISelect = (
  | {
      multiple: true;
      selected?: TSelectValue[];
      onSelect(selected: TSelectValue[]): void;
    }
  | {
      multiple: false;
      selected?: TSelectValue | null;
      onSelect(selected: TSelectValue, option: ISelectItem | null): void;
    }
) &
  ISelectGeneral;

export const Select = (props: ISelect) => {
  const {
    size = "lg",
    label,
    error,
    search,
    disabled,
    onSelect,
    selected,
    children,
    multiple,
    menuArray,
    className,
    openBottom,
    placeholder,
    addButtonText,
    addButtonOnClick,
    isMainPlaceholder,
    searchPlaceholder,
    isCheckBoxOnRightSide,
  } = props;

  const [openList, setOpenList] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (!openList) {
      setSearchValue("");
    }
  }, [openList]);

  const ref = useRef(null);

  const handleAdd = () => {
    setOpenList(false);
    addButtonOnClick && addButtonOnClick();
  };

  const handleSelect = (item: ISelectItem) => () => {
    if (multiple) {
      let values = selected ? [...selected] : [];
      if (values.includes(item.key)) {
        values = values.filter((v) => v !== item.key);
      } else {
        values.push(item.key);
      }
      onSelect(values);
    } else {
      onSelect(selected === item.key ? 0 : item.key, item);
      setOpenList(false);
    }
  };

  useOnClickOutside(ref, () => setOpenList(false));

  const filteredOptions = useMemo(() => {
    let list = [...menuArray];

    if (searchValue) {
      list = list.filter((item) => {
        const note = item.note ? (item.note + "").toLowerCase() : null;
        const label = (item.label + "").toLowerCase();
        const search = searchValue.toLowerCase();
        return label.includes(search) || (note ? note.includes(search) : false);
      });
    }

    // list.sort((a, b) => (a["key"] < b["key"] ? 1 : -1))

    return list;
  }, [menuArray, searchValue]);

  const selectedOption =
    selected != null
      ? multiple
        ? menuArray.filter((item) => selected.includes(item.key)).length
        : menuArray.find((item) => item.key === selected)?.label
      : undefined;

  return (
    <div
      ref={ref}
      className={clsx(
        styles.container,
        error && styles.error,
        disabled && styles.disabled,
        openBottom ? styles.bottomContainer : styles.topContainer,
        className
      )}
    >
      {children ? (
        cloneElement(children, { onClick: () => setOpenList(!openList) })
      ) : (
        <button
          type="button"
          onClick={() => setOpenList(!openList)}
          disabled={disabled}
          className={clsx(
            styles.button,
            styles[`${size}Size`],
            openList && styles.buttonFocus
          )}
        >
          <span className={clsx(!!selectedOption && styles.selectedSpan)}>
            {selectedOption
              ? multiple
                ? `Выбрано: ${selectedOption}`
                : selectedOption
              : label
              ? isMainPlaceholder
                ? placeholder
                : ""
              : placeholder}
          </span>
          <img src={arrowDownIcon} alt="" />

          {label && (
            <label
              className={clsx(
                styles.label,
                (!!selectedOption || isMainPlaceholder) && styles.focused
              )}
            >
              {label}
            </label>
          )}

          <fieldset aria-hidden="true">
            <legend>
              <span>{label}</span>
            </legend>
          </fieldset>
        </button>
      )}

      {openList && (
        <div className={styles.listContainer}>
          {search && (
            <div className={styles.searchItem}>
              <Input
                size="md"
                value={searchValue}
                onChange={setSearchValue}
                placeholder={searchPlaceholder || "Поиск"}
              />
            </div>
          )}

          {addButtonText && (
            <button
              type="button"
              onClick={handleAdd}
              className={clsx(
                styles.addButton,
                styles[`${size}ButtonListSize`]
              )}
            >
              <img src={plusIcon} alt="" /> <span>{addButtonText}</span>
            </button>
          )}

          {filteredOptions.map((item) => (
            <button
              key={item.key}
              type="button"
              onClick={handleSelect(item)}
              data-selected={selected === item.key}
              className={clsx(
                styles.buttonList,
                styles[`${size}ButtonListSize`]
              )}
            >
              {Array.isArray(selected) ? (
                <CheckBox
                  label={
                    <div className="text-left">
                      {item.label}
                      {!!item.note && (
                        <>
                          <br />
                          <small className="text-secondary">{item.note}</small>
                        </>
                      )}
                    </div>
                  }
                  checked={selected.includes(item.key)}
                  readOnly
                  isCheckBoxOnRightSide={isCheckBoxOnRightSide}
                />
              ) : (
                <div className="text-left">
                  <span>{item.label}</span>
                  {!!item.note && (
                    <small className="text-secondary">{item.note}</small>
                  )}
                </div>
              )}
            </button>
          ))}

          {!filteredOptions.length && !searchValue && (
            <div className={styles.empty}>Список пуст</div>
          )}
          {!filteredOptions.length && !!searchValue && (
            <div className={styles.empty}>Ничего не найдено</div>
          )}
        </div>
      )}
      {error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
};
