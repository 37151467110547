import clsx from "clsx";
import { Carousel } from "nuka-carousel";
import ModalCarousel from "../modal/CarouselModal";
import { useState } from "react";
import "./styles.scss";

export type CarouselImage = {
  src: string;
  alt?: string;
};

type ImageCarouselProps = {
  images: CarouselImage[];
  className?: string;
};

const ImageCarousel: React.FC<ImageCarouselProps> = (props) => {
  const { images, className } = props;

  const isEmpty = images.length === 0;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = (value: false) => setIsOpen(value);
  const handleOpen = (value: true) => setIsOpen(value);

  return (
    <div className={clsx(className)}>
      {!isEmpty ? (
        <div>
          <Carousel showDots showArrows title="Carousel">
            {images.map((image) => {
              return (
                <img
                  src={image.src}
                  alt={image.alt}
                  onClick={() => handleOpen(true)}
                  className="img-carousel"
                />
              );
            })}
          </Carousel>
        </div>
      ) : (
        <div>Изображений нет</div>
      )}
      <ModalCarousel images={images} opened={isOpen} onClose={handleClose} />
    </div>
  );
};

export default ImageCarousel;
