import { ISelectItemExpended } from "../../../../interfaces";

export function getAddress(
  isSecondarySwitcher: boolean,
  streets: ISelectItemExpended[],
  buildings: ISelectItemExpended[] | null | undefined,
  complexes: ISelectItemExpended[],
  buildingsComplex: ISelectItemExpended[],
  selectedStreet: number | null,
  selectedBuildingSecondary: number | null,
  selectedComplex: number | null,
  selectedBuildingPrimary: number | null
) {
  let firstData = "";
  let secondData = "";

  if (isSecondarySwitcher) {
    const street = streets.find(
      (street) => street.key === selectedStreet
    )?.label;

    const building = buildings?.find(
      (building) => building?.key === selectedBuildingSecondary
    )?.label;

    if (street) {
      firstData = String(street);
    }
    if (building) {
      secondData = String(building);
    }
  } else {
    const complex = complexes.find(
      (street) => street.key === selectedComplex
    )?.label;

    const buildingComplex = buildingsComplex.find(
      (building) => building?.key === selectedBuildingPrimary
    )?.label;

    if (complex) {
      firstData = String(complex);
    }
    if (buildingComplex) {
      secondData = String(buildingComplex);
    }
  }
  return firstData + " " + secondData;
}
