import { Carousel } from "nuka-carousel";
import { CarouselImage } from "../../carousel";
import { ComplexAnimatedModal } from "../ComplexAnimatedModal";
import styles from "./styles.module.scss";

interface IModalCarouselProps {
  images: CarouselImage[];
  opened: boolean;
  onClose: (value: false) => void;
}

const ModalCarousel = ({ images, opened, onClose }: IModalCarouselProps) => {
  return (
    <ComplexAnimatedModal opened={opened} onClose={() => onClose(false)}>
      <div className={styles.carouselModal}>
        <Carousel showDots showArrows={"hover"} title="Carousel">
          {images.map((image) => {
            return <img src={image.src} alt={image.alt} width={"100%"} />;
          })}
        </Carousel>
      </div>
    </ComplexAnimatedModal>
  );
};

export default ModalCarousel;
