import clsx from "clsx";
import { Oval } from "react-loader-spinner";
import styles from "./styles.module.scss";

export type ButtonProps = {
  label: string;
  className?: string;
  disabled?: boolean;
  variant?:
    | "default"
    | "gray"
    | "transparent"
    | "danger"
    | "dark"
    | "secondary";
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
};

const Button: React.FC<ButtonProps> = (props) => {
  const {
    label,
    variant = "default",
    loading,
    onClick,
    disabled,
    className,
    style,
  } = props;

  return (
    <button
      style={style}
      type="button"
      onClick={onClick}
      disabled={loading || disabled}
      className={clsx(styles.button, styles[variant], className)}
    >
      <span className={styles.content}>
        {loading && <Oval wrapperClass={styles.spinner} strokeWidth={4} />}
        {label}
      </span>
    </button>
  );
};

export default Button;
