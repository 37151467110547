import useBuildingListByStreet from "../../../../../../../api/hooks/use-building-list-by-street";
import { MarketTypes } from "../../../../../../../intrefaces";
import { ISelectItem } from "../../../../../../ui/select";
import { useState } from "react";

const useBuildingSecondary = (
  streets: ISelectItem[],
  selectedStreet: number | null
) => {
  const [selectedBuildingSecondary, setSelectedBuildingSecondary] = useState<
    number | null
  >(null);

  const paramsBuildingListByStreet = {
    street: (streets as ISelectItem[]).find(
      (item) => item.key === selectedStreet
    )?.label,
    marketType: MarketTypes.SECONDARY,
  };

  const {
    buildings: buildingsSecondary,
    error: errorBuildings,
    isLoading: isLoadingBuildings,
  } = useBuildingListByStreet(
    selectedStreet ? paramsBuildingListByStreet : undefined,
    true
  );

  const handleSetBuilding = (building: number) => {
    setSelectedBuildingSecondary(building);
  };

  const handleResetBuilding = () => {
    setSelectedBuildingSecondary(null);
  };

  return {
    selectedBuildingSecondary,
    buildingsSecondary,
    errorBuildings,
    isLoadingBuildings,
    handleSetBuilding,
    handleResetBuilding,
  };
};

export default useBuildingSecondary;
