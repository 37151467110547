import useStreetList from "../../../../../../../api/hooks/use-street-all";
import { useEffect, useState } from "react";

const useStreet = (isFetch: boolean) => {
  const [selectedStreet, setSelectedStreet] = useState<number | null>(null);
  const [isMounted, setMounted] = useState(false);

  const {
    streets,
    error: errorStreets,
    isLoading: isLoadingStreets,
    mutate,
  } = useStreetList(true, isFetch);

  useEffect(() => {
    if (isFetch && !isMounted) {
      mutate();
      setMounted(true);
    }
  }, [isFetch]);

  useEffect(() => {
    if (errorStreets) {
      console.log(errorStreets);
    }
  }, [errorStreets]);

  const handleSetStreet = (street: number) => {
    setSelectedStreet(street);
  };

  const handleResetStreet = () => {
    setSelectedStreet(null);
  };

  return {
    selectedStreet,
    streets,
    isLoadingStreets,
    handleSetStreet,
    handleResetStreet,
  };
};

export default useStreet;
