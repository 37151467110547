import { CSSProperties } from "react";
import styles from "./styles.module.scss";

export type DottedListItem = { label: string; value: string | number };

type DottedListProps = {
  header?: string;
  items: DottedListItem[];
  className?: string;
  styleLabel?: CSSProperties | undefined;
  styleValue?: CSSProperties | undefined;
  minWidth?: number | undefined;
};

const DottedList: React.FC<DottedListProps> = (props) => {
  const { header, items, className, styleLabel, styleValue, minWidth } = props;

  return (
    <div className={className} style={{ minWidth }}>
      {header && <label className={styles.header}>{header}</label>}
      <ul className={styles.list}>
        {items.map(({ label, value }) => (
          <li key={label} className={styles.listItem}>
            <span className={styles.label} style={styleLabel}>
              {label}
            </span>
            <span className={styles.dashes} />
            <span className={styles.value} style={styleValue}>
              {value}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DottedList;
