import styles from "./styles.module.scss";
import clsx from "clsx";
import React from "react";

export type TCheckboxStatus = "none" | "some" | "all";

export interface ICheckBoxProps {
  name?: string;
  label?: string | React.ReactNode;
  status?: TCheckboxStatus;
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  style?: React.CSSProperties | undefined;
  isCheckBoxOnRightSide?: boolean;
  isOptionKey?: boolean;
  onChange?: (
    checked: boolean,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export const CheckBox = (props: ICheckBoxProps) => {
  const {
    name,
    label,
    status,
    checked,
    disabled,
    readOnly,
    style,
    className,
    isCheckBoxOnRightSide,
    isOptionKey,
    onChange,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isOptionKey) {
      // @ts-ignore
      onChange && onChange()();
    } else {
      onChange && onChange(event.target.checked, event);
    }
  };

  return (
    <label
      className={clsx(
        className,
        styles.checkBoxContainer,
        disabled && styles.disabled,
        readOnly && "readonly"
      )}
      style={style}
      onClick={(e) => e.stopPropagation()}
    >
      {isCheckBoxOnRightSide && <span className={styles.label}>{label}</span>}
      <div className={clsx(styles.check, styles.option)}>
        <input
          type="checkbox"
          name={name}
          hidden
          checked={checked || status === "all" || status === "some"}
          disabled={disabled}
          onChange={handleChange}
          className={clsx(
            styles.checkInput,
            status && styles[`status-${status}`]
          )}
        />

        <span className={styles.checkBox} />
      </div>

      {!isCheckBoxOnRightSide && <span className={styles.label}>{label}</span>}
    </label>
  );
};
