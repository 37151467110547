import { useEffect, useState } from "react";
import { IDataModal } from "../../..";
import { ComplexAnimatedModal } from "../../../../../ui/modal/ComplexAnimatedModal";
import FormRequestTradeIn from "../../forms/formRequest/formRequestExtended";
import styles from "./styles.module.scss";
import useAuthUserOtpVerify from "../../../../../../api/hooks/use-auth-user-otp-verify";
import { toast } from "react-toastify";
import useAuthUserPatch from "../../../../../../api/hooks/use-auth-patch-user";
import useTradeIntMissingObject, {
  ITradeIntMissingObjectRequest,
} from "../../../../../../api/hooks/use-trade-in-missing-object";
import { transformPhone } from "../../../../../../lib/utils";

export interface IDataTradeIn {
  numberPhone: string;
  otp: string;
  firstName: string;
  lastName: string;
  patronymic?: string;

  userAddress: string;
  userApartmentNumber: string;
  userApartmentAreaInSqM: string;

  address: string;
  apartmentType?: string;
  areaInSqM: string;
}
interface IModalTradeInProps {
  isOpen: boolean;
  onDismiss: (value: boolean) => void;
  data: IDataModal;
  isSecondary: boolean;
  type: "sell" | "by";
}

const TEXT_SELL =
  "Для подготовки предложения  по программе Trade-in укажите номер  квартиры  для обмена.";
const TEXT_BY = "Какую квартиру в Красноярске вы хотели бы обменять на новую. ";

const ModalTradeIn = ({
  isOpen,
  data,
  type,
  isSecondary,
  onDismiss,
}: IModalTradeInProps) => {
  const initDataTradeIn = {
    numberPhone: "",
    otp: "",
    firstName: "",
    lastName: "",
    patronymic: "",

    userAddress: "",
    userApartmentNumber: "",
    userApartmentAreaInSqM: "",

    address: data.address,
    apartmentType: data.typeApartment,
    areaInSqM: data.areaInSqM,
  };

  const [dataTradeIn, setDataTradeIn] = useState<IDataTradeIn>(initDataTradeIn);

  const onChangeData = (value: string, key: string) => {
    setDataTradeIn((prev) => ({ ...prev, [key]: value }));
  };

  const {
    error: authUserOtpVerifyError,
    isMutating: authUserOtpVerifyIsMutating,
    trigger: authUserOtpVerifyTrigger,
  } = useAuthUserOtpVerify();

  const {
    error: authUserPatchError,
    isMutating: authUserPatchIsMutating,
    trigger: authUserPatchTrigger,
  } = useAuthUserPatch();

  const {
    error: missingObjectError,
    isMutating: missingObjectIsMutating,
    trigger: missingObjectTrigger,
  } = useTradeIntMissingObject();

  useEffect(() => {
    setDataTradeIn(initDataTradeIn);
  }, [isOpen]);

  const getSentData = () => {
    let sentData: ITradeIntMissingObjectRequest = {};

    if (isSecondary) {
      if (type === "by") {
        // купить в trade in вторичка
        sentData = {
          objectRedemption: {
            address: dataTradeIn.userAddress,
            square: Number(dataTradeIn.userApartmentAreaInSqM),
            apartmentNumber: Number(dataTradeIn.userApartmentNumber),
          },
          address: dataTradeIn.address,
          square: Number(dataTradeIn.areaInSqM),
        };
      } else {
        // продать trade-in вторичка
        sentData = {
          objectRedemption: {
            address: dataTradeIn.address,
            square: Number(dataTradeIn.areaInSqM),
            apartmentNumber: Number(dataTradeIn.userApartmentNumber),
          },
        };
      }
    } else {
      if (type === "by") {
        // купить в trade in первичка
        sentData = {
          objectRedemption: {
            address: dataTradeIn.userAddress,
            square: Number(dataTradeIn.userApartmentAreaInSqM),
            apartmentNumber: Number(dataTradeIn.userApartmentNumber),
          },
          address: dataTradeIn.address,
          square: Number(dataTradeIn.areaInSqM),
          apartmentTypeId: data.typeApartmentId,
        };
      } else {
        // продать trade-in первичка
        sentData = {
          objectRedemption: {
            address: dataTradeIn.address,
            apartmentNumber: Number(dataTradeIn.userApartmentNumber),
            square: Number(dataTradeIn.areaInSqM),
            apartmentTypeId: data.typeApartmentId,
          },
        };
      }
    }
    return sentData;
  };

  const handleSubmit = async () => {
    try {
      await authUserOtpVerifyTrigger({
        phone: transformPhone(dataTradeIn.numberPhone),
        otpCode: dataTradeIn.otp,
      });

      await authUserPatchTrigger({
        lastName: dataTradeIn.lastName,
        firstName: dataTradeIn.firstName,
        patronymic: dataTradeIn.patronymic,
      });

      await missingObjectTrigger(getSentData());

      toast.success(
        "Ваша заявка принята, наш эксперт свяжется с вами в течении 2х часов в рабочее время."
      );
      onDismiss(false)
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <ComplexAnimatedModal opened={isOpen} onClose={() => onDismiss(false)}>
      <div className={styles.commonModalContainer}>
        <FormRequestTradeIn
          title="Заявка на расчет trade-in"
          text={type === "sell" ? TEXT_SELL : TEXT_BY}
          buttonLabel="Оставить заявку на trade-in"
          titleForPatForm="Квартира для обмена:" 
          onClick={handleSubmit}
          type={type}
          labelForApartment="Желаемая квартира:"
          data={dataTradeIn}
          onChange={onChangeData}
          isLoading={
            authUserOtpVerifyIsMutating ||
            missingObjectIsMutating ||
            authUserPatchIsMutating
          }
        />
      </div>
    </ComplexAnimatedModal>
  );
};

export default ModalTradeIn;
