import { Input } from "../../../../../ui/input";
import style from "./styles.module.scss";
import InputSQMeter from "../../../../../ui/input/input-sq-meter";
import Button from "../../../../../ui/button";
import { SQ_METER_SIGN } from "../../../../../../lib/constants";
import { CheckBox } from "../../../../../ui/checkbox/checkboxMain";
import { Link } from "react-router-dom";
import { useState } from "react";
import UserForm from "./formRequestUser";
import { IDataBy } from "../../modals/modalAnalogs/modalBy";
import { IDataTradeIn } from "../../modals/modalAnalogs/modalTradeIn";
import InputNumber from "../../../../../ui/input/input-number";

type IDataFormRequest = IDataBy | IDataTradeIn;

interface IFormRequestExtendedProps {
  title: string;
  text: string;
  data: IDataFormRequest;
  titleForPatForm: string;
  buttonLabel: string;
  onClick: () => void;
  onChange: (value: string, key: string) => void;
  type: "by" | "sell";
  labelForApartment: string;
  isLoading: boolean;
}

const FormRequestExtended = ({
  title,
  text,
  data,
  buttonLabel,
  titleForPatForm,
  type,
  labelForApartment,
  isLoading,
  onClick,
  onChange,
}: IFormRequestExtendedProps) => {
  const [isCheck, setIsCheck] = useState(false);

  let disabled = !(
    isCheck &&
    data.firstName &&
    data.lastName &&
    data.numberPhone &&
    data.otp &&
    data.userApartmentNumber
  );

  if ("userApartmentAreaInSqM" in data) {
    disabled = disabled && Boolean(data.userApartmentAreaInSqM);
  }

  if ("userAddress" in data) {
    disabled = disabled && Boolean(data.userAddress);
  }

  return (
    <div className={style.formRequest}>
      <div className={style.formRequest__container}>
        <div className={style.formRequest__title}>
          <h3>{title}</h3>
        </div>
        <div className={style.formRequest__text}>
          <p>{text}</p>
        </div>

        <form
          className={style.formRequest__form}
          onSubmit={(e) => e.preventDefault()}
        >
          <h4>Ваши данные:</h4>
          <UserForm
            data={{
              numberPhone: data?.numberPhone,
              otp: data?.otp,
              firstName: data?.firstName,
              lastName: data?.lastName,
              patronymic: data?.patronymic,
            }}
            onChange={onChange}
          />

          {type === "by" && (
            <div>
              <h4>{titleForPatForm}</h4>
            </div>
          )}
          {type === "by" && "userAddress" in data && (
            <div className={style.formRequest__address}>
              <Input
                label="Полный адрес"
                placeholder="Мира 15"
                value={data.userAddress}
                onChange={(value) => onChange(value, "userAddress")}
                isMainPlaceholder
              />
            </div>
          )}
          {type === "by" && "userApartmentAreaInSqM" in data && (
            <div className={style.formRequest__square}>
              <InputSQMeter
                value={data.userApartmentAreaInSqM}
                onChange={(value) => onChange(value, "userApartmentAreaInSqM")}
                placeholder={`22.2 ${SQ_METER_SIGN}`}
              />
            </div>
          )}
          {type === "by" && (
            <div className={style.formRequest__apartmentNumber}>
              <InputNumber
                label="Номер квартиры"
                value={data.userApartmentNumber}
                placeholder="15"
                length={8}
                isMainPlaceholder
                onChange={(value) => onChange(value, "userApartmentNumber")}
              />
            </div>
          )}

          <div>
            <h4>{labelForApartment}</h4>
          </div>
          <div className={style.formRequest__address}>
            <Input value={data.address} label="Адрес квартиры" readOnly />
          </div>
          {data.apartmentType && (
            <div className={style.formRequest__apartmentType}>
              <Input value={data.apartmentType} label="Тип квартиры" readOnly />
            </div>
          )}
          <div className={style.formRequest__areaInSqM}>
            <InputSQMeter
              value={data.areaInSqM + " " + SQ_METER_SIGN}
              readOnly
            />
          </div>

          {type === "sell" && (
            <div className={style.formRequest__apartmentNumber}>
              <InputNumber
                label="Номер квартиры"
                value={data.userApartmentNumber}
                placeholder="15"
                length={8}
                isMainPlaceholder
                onChange={(value) => onChange(value, "userApartmentNumber")}
              />
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckBox
              checked={isCheck}
              onChange={(value) => setIsCheck(value)}
            />
            <div style={{ marginTop: 18 }}>
              <span>Отправляя заявку я соглашаюсь с </span>
              <Link
                target={"_blank"}
                to={"https://reit-invest.ru/privacy_policy"}
              >
                политикой конфиденциальности
              </Link>
              <span> и </span>
              <Link target={"_blank"} to={"https://reit-invest.ru/consent"}>
                согласием на обработку персональных данных.
              </Link>
            </div>
          </div>
        </form>

        <div className={style.formRequest__button}>
          <Button
            label={buttonLabel}
            onClick={onClick}
            disabled={disabled}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default FormRequestExtended;
