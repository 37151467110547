import Portal from "../portal";
import { useMount } from "./useMount";
import { Layout } from "./Layout";
import { useEffect } from "react";

interface ComplexAnimatedModalProps {
  opened: boolean;
  onClose: () => void;
  children: React.ReactElement;
}

export const ComplexAnimatedModal = ({
  opened,
  onClose,
  children,
}: ComplexAnimatedModalProps) => {
  const { mounted } = useMount({ opened });

  useEffect(() => {
    if (opened) {
      const setBodyOverflow = () => {
        document.body.style.overflow = "auto";
        document.body.style.touchAction = "auto";
      };
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
      return () => setBodyOverflow();
    }
  }, [opened]);

  if (!mounted) {
    return null;
  }

  return (
    <Portal>
      <Layout onClose={onClose} opened={opened}>
        {children}
      </Layout>
    </Portal>
  );
};
