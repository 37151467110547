import { useEffect, useState } from "react";
import useAuthUserNumberPhone from "../../../../../../api/hooks/use-auth-user-number-phone";
import Button from "../../../../../ui/button";
import { Input } from "../../../../../ui/input";
import style from "./styles.module.scss";
import clsx from "clsx";
import InputNumber from "../../../../../ui/input/input-number";
import { transformPhone } from "../../../../../../lib/utils";
interface IUserData {
  numberPhone: string;
  otp: string;
  firstName: string;
  lastName: string;
  patronymic?: string;
}
interface IUserFormProps {
  onChange: (value: string, key: string) => void;
  data: IUserData;
}

const UserForm = ({ data, onChange }: IUserFormProps) => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [time, setTime] = useState(30);
  const [timerActive, setTimerActive] = useState(false);

  const validateNumberPhone = () => {
    const regPhone = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
    if (regPhone.test(data.numberPhone)) {
      return true;
    }
    return false;
  };

  const {
    error: dataAuthNumPhoneError,
    isMutating: dataAuthNumPhoneIsMutating,
    trigger: dataAuthNumPhoneTrigger,
  } = useAuthUserNumberPhone();

  useEffect(() => {
    if (timerActive) {
      const timerID = setInterval(() => {
        if (time) {
          setTime((prev) => {
            if (prev === 0) {
              clearInterval(timerID);
              setTime(30);
              setTimerActive(false);
              return prev;
            }
            return prev - 1;
          });
        }
      }, 1000);

      return () => clearInterval(timerID);
    }
  }, [timerActive]);

  const onClickConfirmPhone = async () => {
    try {
      setIsConfirm(true);
      await dataAuthNumPhoneTrigger({
        phone: transformPhone(data.numberPhone),
      });
      setTimerActive(true);
    } catch (error) {}
  };

  return (
    <div className={style.formRequest__user}>
      <div className={style.formRequest__phone}>
        <Input
          label="Номер телефона"
          type="tel"
          onChange={(value) => onChange(value, "numberPhone")}
          placeholder="89500561452"
          value={data.numberPhone}
          isMainPlaceholder
        />
        <div
          className={clsx(
            style.formRequest__phoneConfirm,
            !validateNumberPhone() && style.formRequest__phoneConfirm_hide
          )}
        >
          <Button
            onClick={onClickConfirmPhone}
            label={time === 30 ? "подтвердить" : `${time} c`}
            style={{ height: "38px", fontSize: 15, paddingBottom: "30px" }}
            disabled={time !== 30}
          />
        </div>
      </div>
      {isConfirm && (
        <div className={style.formRequest__opt}>
          <InputNumber
            label="Код подтверждения"
            onChange={(value) => onChange(value, "otp")}
            length={6}
            placeholder="123456"
            value={data.otp}
            isMainPlaceholder
          />
        </div>
      )}
      <div className={style.formRequest__fio}>
        <Input
          label="Фамилия"
          onChange={(value) => onChange(value, "firstName")}
          placeholder="Иванов"
          value={data.firstName}
          isMainPlaceholder
        />
      </div>
      <div className={style.formRequest__fio}>
        <Input
          label="Имя"
          onChange={(value) => onChange(value, "lastName")}
          placeholder="Иван"
          value={data.lastName}
          isMainPlaceholder
        />
      </div>
      <div className={style.formRequest__fio}>
        <Input
          label="Отчество"
          onChange={(value) => onChange(value, "patronymic")}
          placeholder="Иванович"
          value={data.patronymic}
          isMainPlaceholder
        />
      </div>
    </div>
  );
};

export default UserForm;
