import { useState } from "react";
import { Input } from ".";
import { SQ_METER_SIGN } from "../../../lib/constants";

interface IInputSQMeterProps {
  onChange?: (value: string) => void;
  value: string | null | undefined;
  error?: string;
  isDisable?: boolean;
  readOnly?: boolean;
  placeholder?: string;
}

const STYLE_ERROR = { margin: 0, padding: 0, fontSize: 12, color: "#e74c3c" };

const InputSQMeter = ({
  onChange,
  value,
  error,
  isDisable,
  readOnly,
  placeholder,
}: IInputSQMeterProps) => {
  const [isBlur, setIsBluer] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const handleChangeArea = (apartmentArea: string) => {
    const regex = /^(?:\d+(?:[.,]\d+)?|\d*[.,])$/;

    if (
      apartmentArea === "" ||
      apartmentArea === "." ||
      apartmentArea === ","
    ) {
      onChange && onChange("");
      return;
    }

    if (regex.test(apartmentArea)) {
      const arraySplit = apartmentArea.includes(".")
        ? apartmentArea.split(".")
        : apartmentArea.split(",");

      const isValid =
        arraySplit[0].length <= 3 && !arraySplit[1]
          ? true
          : arraySplit[1]?.length <= 3;

      if (isValid) {
        onChange && onChange(apartmentArea.replace(",", "."));
      }
    }
  };

  const handleBluer = () => {
    setIsFocus(false);
    if (value) {
      setIsBluer(true);
    }
  };

  const handleFocus = () => {
    setIsBluer(false);
    if (value) {
      setIsFocus(true);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Input
        label="Площадь квартиры"
        placeholder={placeholder ?? "Укажите площадь"}
        onChange={handleChangeArea}
        value={value ? String(value) : ""}
        sign={isBlur && value?.length ? SQ_METER_SIGN : undefined}
        onBlur={handleBluer}
        onFocus={handleFocus}
        isMainPlaceholder={true}
        maxLength={8}
        error={!!error}
        disabled={isDisable}
        readOnly={readOnly}
      />
      <div
        style={{
          margin: 0,
          padding: 0,
          position: "absolute",
          bottom: -15,
          left: 2,
        }}
      >
        <span style={STYLE_ERROR}>{error}</span>
      </div>
    </div>
  );
};

export default InputSQMeter;
