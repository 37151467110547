import useSWR from "swr";
import fetcher from "../../lib/fetcher";
import { ISelectItem } from "../../components/ui/select";
import { MarketTypes } from "../../intrefaces";
import { sortByTitle } from "../../lib/utils";

export type IBuildingListByStreetResponse = {
  house: string;
  id: number;
  coordinates?: { latitude: number; longitude: number };
}[];

export type IBuildingListByStreetResponseWrapper = {
  data: IBuildingListByStreetResponse;
};

export interface ISelectItemExpended extends ISelectItem {
  coordinates?: { latitude: number; longitude: number } | null;
}

export const useBuildingListByStreet = (
  params?: { street: string | number | undefined; marketType: MarketTypes },
  isTransformForSelect?: boolean,
  fetch = true
) => {
  const { data, error, isLoading, mutate } = useSWR(
    fetch ? [params] : null,
    () =>
      !!params
        ? fetcher
            .swr<IBuildingListByStreetResponseWrapper>(
              "/building/building-by-street",
              {
                params: params,
              }
            )
            .then((res) => res)
        : null
  );

  const transformData = (
    dataRes: IBuildingListByStreetResponseWrapper | undefined | null
  ): ISelectItemExpended[] => {
    if (dataRes) {
      const dataTransformed = dataRes?.data?.map((item) => ({
        key: item.id,
        label: item.house,
        coordinates: item.coordinates,
      }));

      return sortByTitle(dataTransformed) as ISelectItemExpended[];
    }
    return [];
  };

  return {
    buildings: isTransformForSelect ? transformData(data) : data,
    error,
    isLoading,
    mutate,
  };
};

export default useBuildingListByStreet;
