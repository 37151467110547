import useBuildingsByComplexId from '../../../../../../../api/hooks/use-building-list-by-complex';
import { useEffect, useState } from 'react';

const useBuildingComplex = (selectedComplex: number | null) => {
  const [selectedBuildingPrimary, setSelectedBuildingPrimary] = useState<number | null>(null);

  const {
    building: buildingsComplex,
    isLoading: isLoadingBuildingComplexes,
    error: errorBuildingComplexes,
  } = useBuildingsByComplexId(selectedComplex, true);

  const handleSetComplexBuilding = (building: number) => {
    setSelectedBuildingPrimary(building);
  };

  const handleResetBuildingComplex = () => {
    setSelectedBuildingPrimary(null);
  };

  useEffect(() => {
    if (errorBuildingComplexes) {
      console.log(errorBuildingComplexes);
    }
  }, [errorBuildingComplexes]);

  return {
    selectedBuildingPrimary,
    buildingsComplex,
    isLoadingBuildingComplexes,
    handleSetComplexBuilding,
    handleResetBuildingComplex,
  };
};

export default useBuildingComplex;
