import { SQ_METER_SIGN } from "../../../../../../../lib/constants";
import { useState } from "react";

const useArea = (
  isSecondary: boolean,
  minMaxArea?: { min: number; max: number } | null
) => {
  const [areaInSqM, setAreaInSqM] = useState<string | null>();
  const [errorAreaInSqM, setErrorAreaInSqM] = useState<string>("");

  const checkValid = () => {
    if (isSecondary) {
      const errorMessage = `Площадь от 8 ${SQ_METER_SIGN} до 300 ${SQ_METER_SIGN}`;

      if (Number(areaInSqM) < 8) {
        setErrorAreaInSqM(errorMessage);
        return false;
      }
      if (Number(areaInSqM) > 300) {
        setErrorAreaInSqM(errorMessage);
        return false;
      }
    } else if (minMaxArea) {
      const errorMessage = `Площадь от ${minMaxArea.min.toFixed(
        2
      )} ${SQ_METER_SIGN} до ${minMaxArea.max.toFixed(2)} ${SQ_METER_SIGN}`;

      if (Number(areaInSqM) < minMaxArea.min) {
        setErrorAreaInSqM(errorMessage);
        return false;
      }
      if (Number(areaInSqM) > minMaxArea.max) {
        setErrorAreaInSqM(errorMessage);
        return false;
      }
    }
    setErrorAreaInSqM("");
    return true;
  };

  const handleChangeApartmentArea = (value: string) => {
    setErrorAreaInSqM("");
    setAreaInSqM(value);
  };

  const handleResetArea = () => {
    setAreaInSqM(null);
    setErrorAreaInSqM("");
  };

  return {
    areaInSqM,
    errorAreaInSqM,
    checkValid,
    handleChangeApartmentArea,
    handleResetArea,
  };
};

export default useArea;
