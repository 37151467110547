import Switchbox from "../../../../ui/switch";
import style from "./styles.module.scss";
import clsx from "clsx";

interface IHeaderEstimatesProps {
  switcher: boolean;
  onSwitch: (value: boolean) => void;
}

const HeaderEstimate = ({ switcher, onSwitch }: IHeaderEstimatesProps) => {
  return (
    <div className={style.header}>
      <div className={style.header__container}>
        <div
          className={clsx(style.header__case, style.case)}
          onClick={() => onSwitch(false)}
        >
          <h3
            className={clsx(
              style.case__top,
              switcher && style.case__top_gray,
              !switcher && style.case__top_line
            )}
          >
            Новостройка
          </h3>
          <p className={style.case__bottom}>еще строится</p>
        </div>

        <div className={style.case__switcher}>
          <div>
            <Switchbox
              checked={switcher}
              onChange={onSwitch}
              className=".slider_estimate"
            />
          </div>
        </div>

        <div
          className={clsx(style.header__case, style.case)}
          onClick={() => onSwitch(true)}
        >
          <h3
            className={clsx(
              style.case__top,
              style.case__top_green,
              !switcher && style.case__top_gray,
              switcher && style.case__top_line
            )}
          >
            Вторичка
          </h3>
          <p className={style.case__bottom}>готовое жилье</p>
        </div>
      </div>
    </div>
  );
};

export default HeaderEstimate;
