import FormRequest from "../../forms/formRequest/formRequest";
import { IDataModal } from "../../..";
import { ComplexAnimatedModal } from "../../../../../ui/modal/ComplexAnimatedModal";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import useAuthUserOtpVerify from "../../../../../../api/hooks/use-auth-user-otp-verify";
import useAuthUserPatch from "../../../../../../api/hooks/use-auth-patch-user";
import { RUBLE_SIGN } from "../../../../../../lib/constants";
import useDealRequest, {
  EAddFacilityDealRequestType,
  EMarketType,
  IDealRequest,
} from "../../../../../../api/hooks/use-deal-request";
import { toast } from "react-toastify";
import { transformPhone } from "../../../../../../lib/utils";
interface IModalSupportDealProps {
  isOpen: boolean;
  onDismiss: (value: boolean) => void;
  data: IDataModal;
  title?: string;
  type?: "service" | "support";
}
export interface IDataSupport {
  numberPhone: string;
  otp: string;
  firstName: string;
  lastName: string;
  patronymic?: string;

  address: string;
  apartmentType?: string;
  areaInSqM: string;
}

const TEXT_1 = `Юридическое сопровождение  и проведение сделок любой сложности. Стоимость 30 000 ${RUBLE_SIGN}`;

const TEXT_2 = `Отправьте заявку и мы отправим вам экспертный расчет рыночной стоимости в течении 2х рабочих дней.`;

const ModalSupportDeal = ({
  isOpen,
  data,
  title,
  type = "service",
  onDismiss,
}: IModalSupportDealProps) => {
  const initDataSupport = {
    numberPhone: "",
    otp: "",
    firstName: "",
    lastName: "",
    patronymic: "",

    address: data.address,
    apartmentType: data.typeApartment,
    areaInSqM: data.areaInSqM,
  };

  const [dataSupport, setDataSupport] = useState<IDataSupport>(initDataSupport);

  const {
    error: errorDealRequest,
    isMutating: isMutatingDealRequest,
    trigger: triggerDealRequest,
  } = useDealRequest();

  const onChangeData = (value: string, key: string) => {
    setDataSupport((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    setDataSupport((prev) => ({
      ...prev,
      address: data.address,
      apartmentType: data.typeApartment,
      areaInSqM: data.areaInSqM,
    }));
  }, [data]);

  const {
    error: authUserOtpVerifyError,
    isMutating: authUserOtpVerifyIsMutating,
    trigger: authUserOtpVerifyTrigger,
  } = useAuthUserOtpVerify();

  const {
    error: authUserPatchError,
    isMutating: authUserPatchIsMutating,
    trigger: authUserPatchTrigger,
  } = useAuthUserPatch();

  useEffect(() => {
    setDataSupport(initDataSupport);
  }, [isOpen]);

  const getSentData = () => {
    const typeRequest =
      type === "service"
        ? EAddFacilityDealRequestType.ServiceDealNoSystem
        : EAddFacilityDealRequestType.ErrorCalculation;

    let dataSent: IDealRequest = {
      address: data.address,
      square: Number(data.areaInSqM),
      dealRequestType: typeRequest,
      marketType: EMarketType.Primary,
    };

    if (data.typeApartmentId) {
      dataSent = { ...dataSent, apartmentTypeId: data.typeApartmentId };
    }

    return dataSent;
  };

  const handleSubmit = async () => {
    try {
      await authUserOtpVerifyTrigger({
        phone: transformPhone(dataSupport.numberPhone),
        otpCode: dataSupport.otp,
      });

      await authUserPatchTrigger({
        lastName: dataSupport.lastName,
        firstName: dataSupport.firstName,
        patronymic: dataSupport.patronymic,
      });

      await triggerDealRequest(getSentData());

      toast.success(
        "Ваша заявка принята, наш эксперт свяжется с вами в течении 2х часов в рабочее время."
      );

      onDismiss(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ComplexAnimatedModal opened={isOpen} onClose={() => onDismiss(false)}>
      <div className={styles.commonModalContainer}>
        <FormRequest
          title={title ? title : "Сервис сделки"}
          text={type === "service" ? TEXT_1 : TEXT_2}
          buttonLabel="Обратиться в Reit"
          onClick={handleSubmit}
          data={dataSupport}
          onChange={onChangeData}
          isLoading={authUserOtpVerifyIsMutating}
        />
      </div>
    </ComplexAnimatedModal>
  );
};

export default ModalSupportDeal;
