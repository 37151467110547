import Button from "../../../../ui/button";
import style from "./styles.module.scss";
import { useDataEstimateContext } from "../../hooks/useModalContex";
import { useState } from "react";
import { toast } from "react-toastify";
import ModalSupportDeal from "../modals/modalAnalogs/modalSupportDeal";

const AccompanimentReitEstimate = () => {
  const { dataModal } = useDataEstimateContext();

  const [isOpenModalSupport, setIsOpenModalSupport] = useState(false);

  const handleChangeModalSupport = (value: boolean) => {
    setIsOpenModalSupport(value);
  };

  const handleClickSubsiding = () => {
    if (dataModal) {
      setIsOpenModalSupport(true);
    } else {
      toast.error("Что то пошло ни так, ошибка данных");
    }
  };

  return (
    <div className={style.accompanimentReit}>
      <p>
        <i>
          Если потребуется помощь в проведении сделки, оценки юридических
          рисков, проведении государственной регистрации - наши специалисты
          готовы помочь.
        </i>
      </p>
      <Button
        variant="default"
        label="Сервис сделки"
        onClick={handleClickSubsiding}
      />
      {dataModal && (
        <ModalSupportDeal
          isOpen={isOpenModalSupport}
          onDismiss={handleChangeModalSupport}
          data={dataModal}
        />
      )}
    </div>
  );
};

export default AccompanimentReitEstimate;
