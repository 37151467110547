import { toast } from "react-toastify";
import fetcher from "../../lib/fetcher";
import useSWRMutation from "swr/mutation";

const useAuthUserPatch = () => {
  async function authUserPatch(
    url: string,
    {
      arg,
    }: { arg: { firstName: string; lastName: string; patronymic?: string } }
  ) {
    try {
      const data = await fetcher.patch(url, arg);
      return data.result;
    } catch (error: any) {
      toast.error(
        `Что то не так. Проверьте заполненные данные. Причина: ${error}`
      );
      throw new Error(error);
    }
  }

  const { data, error, isMutating, trigger } = useSWRMutation(
    "/auth/user",
    authUserPatch
  );

  return { data: data, error, isMutating, trigger };
};
export default useAuthUserPatch;
