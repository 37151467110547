import {
  IEstimateSecondaryRequest,
  ISecondaryFacilityEstimateResponseWrapper,
} from "../../components/pages/estimate/interfaces";
import fetcher from "../../lib/fetcher";
import useSWRMutation from "swr/mutation";

const LAST_MESSAGE =
  "Произошла ошибка. Возможно введены не верные данные, проверьте форму. Оставьте заявку, отчет будет подготовлен нашим экспертом.";

enum EErrorEstimateSecondary {
  "Количество этажей у дома объекта меньше 4" = `Автоматический расчет невозможен так как в доме, где находится квартира менее 4х этажей.`,
  "Дом объекта деревянный" = `Автоматический расчет невозможен так как квартира находится деревянном доме.`,
  "Дому больше 60-ти лет" = `Автоматический расчет невозможен так как квартира находится в доме старше 60 лет.`,
  default = `Не достаточно объявлений для автоматического расчета рыночной стоимости.`,
}

const useEstimateSecondary = () => {
  async function fetchEstimateSecondary(
    url: string,
    { arg }: { arg: IEstimateSecondaryRequest }
  ) {
    try {
      const data = await fetcher.post(url, arg);

      if (data.error) {
        switch (data.error) {
          case Object.keys(EErrorEstimateSecondary)[0]: {
            throw (
              EErrorEstimateSecondary[
                "Количество этажей у дома объекта меньше 4"
              ] +
              " " +
              LAST_MESSAGE
            );
          }
          case Object.keys(EErrorEstimateSecondary)[1]: {
            throw (
              EErrorEstimateSecondary["Дом объекта деревянный"] +
              " " +
              LAST_MESSAGE
            );
          }
          case Object.keys(EErrorEstimateSecondary)[2]: {
            throw (
              EErrorEstimateSecondary["Дому больше 60-ти лет"] +
              " " +
              LAST_MESSAGE
            );
          }
          default:
            throw EErrorEstimateSecondary.default + " " + LAST_MESSAGE;
        }
      }

      return data.result as ISecondaryFacilityEstimateResponseWrapper;
    } catch (error) {
      throw EErrorEstimateSecondary.default + " " + LAST_MESSAGE;
    }
  }

  const { data, error, isMutating, trigger } = useSWRMutation(
    "/facility/estimate-secondary",
    fetchEstimateSecondary
  );

  return { data: data?.data, error, isMutating, trigger };
};
export default useEstimateSecondary;
