import React, { RefObject, useState } from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";
import InputMask from "react-input-mask";

const IconClear = "../../../../icons/icon-close.svg";
const IconUnion = "../../../../icons/icon-union.svg";
const IconUnionSlash = "../../../../icons/icon-union-slash.svg";
const IconSearch = "../../../../icons/icon-search.svg";
const IconSuccess = "../../../../icons/icon-success.svg";

export type TInputSize = "sm" | "md" | "lg";
export type TInputType =
  | "text"
  | "tel"
  | "password"
  | "search"
  | "number"
  | "time";
export type TInputVariant =
  | "standard"
  | "actionRight"
  | "iconLeft"
  | "iconAction"
  | "search";

export interface IInputProps {
  name?: string;
  icon?: string;
  mask?: string;
  sign?: string;
  type?: TInputType;
  size?: TInputSize;
  label?: string;
  value?: string | null;
  error?: boolean;
  prefix?: string | number;
  helper?: string;
  variant?: TInputVariant;
  success?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  className?: string;
  placeholder?: string;
  disableReset?: boolean;
  inputRef?: RefObject<any>;
  defaultValue?: string;
  showClearButton?: boolean;
  isMainPlaceholder?: boolean;
  IsDisabledClearButton?: boolean;
  onBlur?(event: React.ChangeEvent<HTMLInputElement>): void;
  onFocus?(event: React.ChangeEvent<HTMLInputElement>): void;
  onReset?(): void;
  onChange?(
    value: string,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement>
  ): void;
}

export const Input = (props: IInputProps) => {
  const {
    name,
    icon,
    sign,
    label,
    size = "lg",
    type,
    mask = "",
    value,
    error,
    helper,
    prefix,
    success,
    variant,
    onBlur,
    onReset,
    onFocus,
    inputRef,
    onChange,
    readOnly,
    disabled,
    className,
    maxLength,
    autoFocus,
    placeholder,
    disableReset,
    defaultValue,
    showClearButton,
    IsDisabledClearButton,
    isMainPlaceholder,
  } = props;

  const [isFocused, setIsFocued] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
    setIsFocued(false);
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFocus && onFocus(event);
    setIsFocued(true);
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    onReset ? onReset() : onChange && onChange("", event);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      if (name === "number") {
        const value = event.target.value.replace(/\D+/g, "");
        if (value !== "") {
          onChange(value, event);
        }
      } else {
        onChange(event.target.value, event);
      }
    }
  };

  const getValue = () => {
    if (sign) {
      return value + " " + sign;
    }
    return value ? value : "";
  };

  return (
    <div className={className}>
      <div
        className={clsx(
          styles.inputContainer,
          styles[`${size}Size`],
          error && styles.errorState,
          success && styles.successState,
          disabled && styles.disabled,
          readOnly && "readonly"
        )}
      >
        {prefix && (isFocused || !!(value + "")) && (
          <div className={styles.prefix}>{prefix}</div>
        )}

        {!icon
          ? type !== "tel" &&
            type !== "password" &&
            (variant === "search" ? (
              <img src={IconSearch} className={styles.iconSearch} alt="Поиск" />
            ) : success ? (
              <img
                src={IconSuccess}
                className={styles.iconSuccess}
                alt="Успешно"
              />
            ) : (
              error && (
                <img
                  src={IconClear}
                  className={styles.iconError}
                  alt="Ошибка"
                />
              )
            ))
          : type !== "tel" &&
            type !== "password" &&
            (variant === "search" ? (
              <img src={icon} className={styles.iconSearch} alt="Поиск" />
            ) : success ? (
              <img src={icon} className={styles.iconSuccess} alt="Успешно" />
            ) : error ? (
              <img src={icon} className={styles.iconError} alt="Ошибка" />
            ) : (
              <img src={icon} className={styles.iconSearch} />
            ))}

        {label && (
          <label
            htmlFor={name}
            className={clsx(
              styles.label,
              (isFocused || !!(value + "") || isMainPlaceholder) &&
                styles.focused
            )}
          >
            {label}
          </label>
        )}

        <fieldset aria-hidden="true">
          <legend>
            <span>{label}</span>
          </legend>
        </fieldset>

        <InputMask
          id={name}
          ref={!!inputRef?.current ? inputRef : undefined}
          name={name}
          mask={type === "tel" ? "+7 999 999 99 99" : mask}
          value={getValue()}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          autoFocus={autoFocus}
          placeholder={placeholder}
          defaultValue={defaultValue}
          maskPlaceholder={null}
          type={
            type === "password"
              ? openPassword
                ? "text"
                : "password"
              : type === "tel"
              ? "text"
              : type
          }
          onKeyUp={(e) => {
            if (e.code === "Enter" || e.keyCode === 13) {
              e.currentTarget.blur();
            }
          }}
        />

        <div className={styles.right}>
          {!disableReset && type !== "password" && !readOnly && !disabled ? (
            <button
              type="button"
              onClick={handleClear}
              className={styles.buttonClear}
              tabIndex={-1}
              disabled={
                IsDisabledClearButton
                  ? true
                  : showClearButton
                  ? false
                  : value
                  ? value?.length < 1
                  : true
              }
            >
              <img src={IconClear} alt="Очистить" />
            </button>
          ) : (
            !disableReset &&
            !readOnly &&
            !disabled && (
              <button
                type="button"
                className={styles.buttonPassword}
                onClick={() => setOpenPassword(!openPassword)}
                tabIndex={-1}
              >
                <img
                  src={openPassword ? IconUnionSlash : IconUnion}
                  alt={!openPassword ? "Открыть пароль" : "Скрыть пароль"}
                />
              </button>
            )
          )}
        </div>
      </div>

      {helper && (
        <p
          className={clsx(
            styles.helper,
            error && styles.errorHelperState,
            success && styles.successHelperState
          )}
        >
          {helper}
        </p>
      )}
    </div>
  );
};
