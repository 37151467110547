import clsx from "clsx";
import styles from "./styles.module.scss";
import BaseCheckbox, { BaseCheckboxProps } from "../checkbox";

type SwitchboxProps = Pick<
  BaseCheckboxProps,
  "checked" | "onChange" | "label" | "labelPosition" | "className"
>;

const Switchbox: React.FC<SwitchboxProps> = (props) => {
  const { className, labelPosition, ...rest } = props;

  return (
    <BaseCheckbox
      {...rest}
      className={clsx(
        styles.container,
        className,
        className && styles["className"]
      )}
      inputClassName={styles.input}
      markClassName={clsx(
        styles.slider,
        labelPosition === "left" && styles.sliderRight
      )}
      labelClassName={
        labelPosition === "left" ? styles.labelLeft : styles.labelRight
      }
    />
  );
};

export default Switchbox;
