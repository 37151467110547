import useApartmentComplexSearch from "../../../../../../../api/hooks/use-apartment-complex-seartch";
import { useEffect, useState } from "react";

const useComplex = (isFetch: boolean) => {
  const [selectedComplex, setSelectedComplex] = useState<number | null>(null);

  const {
    complexes,
    error: errorComplexes,
    isLoading: isLoadingComplexes,
  } = useApartmentComplexSearch(true, isFetch);

  const handleSetComplex = (complex: number) => {
    setSelectedComplex(complex);
  };

  useEffect(() => {
    if (errorComplexes) {
      console.log(errorComplexes);
    }
  }, [errorComplexes]);

  const handleResetComplex = () => {
    setSelectedComplex(null);
  };

  return {
    selectedComplex,
    complexes,
    isLoadingComplexes,
    handleSetComplex,
    handleResetComplex,
  };
};

export default useComplex;
