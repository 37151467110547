import useEstimatePrimary from "../../../../../../../api/hooks/use-estimate-primary";
import useEstimateSecondary from "../../../../../../../api/hooks/use-estimate-secondary";
import Button from "../../../../../../ui/button";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styles from "../styles.module.scss";

const useGetMarketPrice = (
  isSecondarySwitcher: boolean,
  handleModalSupport: (value: boolean) => void,
  resetData: () => void
) => {
  const [error, setError] = useState<string>("");
  const [isShowError, setIsShowError] = useState(false);

  const {
    data: dataSecondary,
    error: errorSecondary,
    isMutating: isMutatingSecondary,
    trigger: triggerSecondary,
  } = useEstimateSecondary();

  const {
    data: dataPrimary,
    error: errorPrimary,
    isMutating: isMutatingPrimary,
    trigger: triggerPrimary,
  } = useEstimatePrimary();

  const onSubmit = async (
    checkValid: () => boolean,
    selectedBuildings: number | null,
    areaInSqM: string | null | undefined,
    selectedComplexBuilding: number | null,
    selectedComplex: number | null,
    selectedTypesApartment: number | null
  ) => {
    const isValid = checkValid();
    if (isValid) {
      if (isSecondarySwitcher) {
        if (selectedBuildings) {
          await triggerSecondary({
            buildingId: selectedBuildings,
            areaInSqM: Number(areaInSqM),
          });
        }
      } else {
        const isValid =
          selectedComplexBuilding && selectedComplex && selectedTypesApartment;
        if (isValid) {
          await triggerPrimary({
            buildingId: selectedComplexBuilding,
            areaInSqM: Number(areaInSqM),
            typeId: selectedTypesApartment,
            complexId: selectedComplex,
          });
        }
      }
    }
  };

  const isMutating = isSecondarySwitcher
    ? isMutatingSecondary
    : isMutatingPrimary;

  useEffect(() => {
    if (!isMutating) {
      setIsShowError(true);
    } else {
      setIsShowError(false);
    }
  }, [isMutating]);

  useEffect(() => {
    if (isSecondarySwitcher) {
      setError("");
    } else {
      setError("");
    }
  }, [isMutatingSecondary, isMutatingPrimary]);

  useEffect(() => {
    if (isSecondarySwitcher) {
      setError(errorSecondary);
    } else {
      setError(errorPrimary);
    }
  }, [errorSecondary, errorPrimary, isMutatingSecondary, isMutatingPrimary]);

  useEffect(() => {
    if (error && !isMutating && isShowError) {
      resetData();
      toast(
        <div className={styles.toast}>
          {error
            .split(".")
            .map(
              (text) =>
                text && <p className={styles.toast__text}>{text + "."}</p>
            )}
          <div className={styles.toast__button}>
            <Button
              label="Оставить заявку"
              onClick={() => {
                handleModalSupport(true);
                toast.dismiss();
              }}
            />
          </div>
        </div>
      );
    }
  }, [error, isMutating, isShowError]);

  return {
    onSubmit,
    dataSecondary,
    dataPrimary,
    isMutatingSecondary,
    isMutatingPrimary,
  };
};

export default useGetMarketPrice;
