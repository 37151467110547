import { toast } from "react-toastify";
import fetcher from "../../lib/fetcher";
import useSWRMutation from "swr/mutation";

const useAuthUserOtpVerify = () => {
  async function authUserOtpVerify(
    url: string,
    { arg }: { arg: { phone: string; otpCode: string } }
  ) {
    try {
      const data = await fetcher.post(url, arg);

      return data.result;
    } catch (error: any) {
      toast.error(`Что то не так. Проверьте номер. Причина: ${error}`);
      throw new Error(error);
    }
  }

  const { data, error, isMutating, trigger } = useSWRMutation(
    "/auth/verify-otp-code",
    authUserOtpVerify
  );

  return { data: data, error, isMutating, trigger };
};
export default useAuthUserOtpVerify;
