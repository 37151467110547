import SwitchBoxWithItems from "../../../../ui/switch/switchBoxWithItems";
import { useState } from "react";
import SellEstimate from "./seellEstimate";
import ByEstimate from "./byEstimate";
import { IMortgageInfo } from "../../interfaces";

import style from "./styles.module.scss";
import clsx from "clsx";

interface IPossibilitiesEstimateProps {
  averageMarketPrice: string;
  mortgageInfo: IMortgageInfo;
  isSecondary: boolean;
}

const PossibilitiesEstimate = ({
  averageMarketPrice,
  mortgageInfo,
  isSecondary,
}: IPossibilitiesEstimateProps) => {
  const [isSellScreen, setIsByScreen] = useState(false);

  const handleSwitchByScreen = (value: boolean) => {
    setIsByScreen(value);
  };

  return (
    <div className={style.possibilities}>
      <div className={style.possibilities__container}>
        <div className={style.possibilities__switcher}>
          <SwitchBoxWithItems
            onChangeSwitcher={handleSwitchByScreen}
            isChecked={isSellScreen}
            classNameSwitcher=".slider_estimate"
          />
        </div>

        <div
          className={clsx(
            style.possibilities__card,
            isSellScreen && style.possibilities__card_green
          )}
        >
          <div className={style.possibilities__title}>
            <h4>{isSellScreen ? "Продать:" : "Купить:"}</h4>
          </div>

          {!isSellScreen ? (
            <div className={style.possibilities__by}>
              <ByEstimate
                averageMarketPrice={averageMarketPrice}
                mortgageInfo={mortgageInfo}
                isSecondary={isSecondary}
              />
            </div>
          ) : (
            <div className={style.possibilities__sell}>
              <SellEstimate isSecondary={isSecondary} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PossibilitiesEstimate;
