import useApartmentBuildingAreaMinMax from "../../../../../../../api/hooks/use-apartment-building-area-limits";
import useTypesBuildingByComplexId from "../../../../../../../api/hooks/use-types-building-by-complex";
import { useEffect, useState } from "react";

const useTypes = (selectedComplexBuilding: number | null) => {
  const [selectedTypesApartment, setSelectedTypesApartment] = useState<
    number | null
  >(null);

  const {
    types: typesApartment,
    isLoading: isLoadingTypes,
    error: errorTypes,
  } = useTypesBuildingByComplexId(selectedComplexBuilding, true);

  const handleSetTypesApartment = (type: number) => {
    setSelectedTypesApartment(type);
  };

  const handleResetTypesApartment = () => {
    setSelectedTypesApartment(null);
  };

  useEffect(() => {
    if (errorTypes) {
      console.log(errorTypes);
    }
  }, [errorTypes]);

  const {
    minMaxArea,
    isLoading: isLoadingMinMaxArea,
    mutate,
  } = useApartmentBuildingAreaMinMax(
    selectedComplexBuilding,
    selectedTypesApartment
  );

  useEffect(() => {
    mutate();
  }, [selectedComplexBuilding, selectedTypesApartment]);

  return {
    typesApartment,
    minMaxArea,
    selectedTypesApartment,
    isLoadingTypes,
    isLoadingMinMaxArea,
    handleSetTypesApartment,
    handleResetTypesApartment,
  };
};
export default useTypes;
