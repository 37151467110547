import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import styles from "./index.module.scss";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className={styles.layout}>
      <div className={styles.layout__container}>
        <Outlet />
        <ToastContainer
          position="bottom-center"
          className={styles.layout__toast}
          autoClose={false}
          closeOnClick={true}
        />
      </div>
    </div>
  );
}

export default App;
