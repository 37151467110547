import useSWR from "swr";
import fetcher from "../../lib/fetcher";
import { ISelectItemExpended } from "./use-building-list-by-street";
import { sortByTitle } from "../../lib/utils";

export type IBuildingsByComplexItemResponse = {
  commissioningQuarter: string;
  coordinates?: { latitude: number; longitude: number };
  id: number;
  title: string;
};
export interface IBuildingsByComplexResponseWrapper {
  data: { buildings: IBuildingsByComplexItemResponse[] };
}

const useBuildingsByComplexId = (
  id?: number | null,
  isTransformForSelect?: boolean
) => {
  const { data, error, isLoading } = useSWR(
    id ? `/apartment-complex/${id}/buildings` : null,
    (url) => (id ? fetcher.swr<IBuildingsByComplexResponseWrapper>(url) : null),
    { errorRetryCount: 0, revalidateOnFocus: false }
  );

  const transformData = (
    dataRes: IBuildingsByComplexResponseWrapper | undefined | null
  ): ISelectItemExpended[] => {
    if (dataRes) {
      const dataTransformed = dataRes.data.buildings.map((item) => ({
        key: item.id,
        label: item.title,
        coordinates: item.coordinates,
      }));
      return sortByTitle(dataTransformed) as ISelectItemExpended[];
    }
    return [];
  };

  return {
    building: isTransformForSelect
      ? transformData(data)
      : data?.data.buildings ?? [],
    error,
    isLoading,
  };
};

export default useBuildingsByComplexId;
