import { memo } from "react";
import { Placemark } from "@pbe/react-yandex-maps";

const mapPin = "../icons/map-pin.svg";
const mapPinSelected = "../icons/map-pin-selected.svg";

type YMapPlacemarkProps = {
  selected?: boolean;
  onClick: () => void;
  defaultGeometry: [number, number];
};

const YMapPlacemark: React.FC<YMapPlacemarkProps> = (props) => {
  const { selected, ...rest } = props;

  const _props = {
    ...rest,
    options: {
      iconLayout: "default#image",
      iconImageSize: [32, 32],
      iconImageHref: selected ? mapPinSelected : mapPin,
    },
  };

  return <Placemark {..._props} />;
};

export default memo(
  YMapPlacemark,
  // Предотвращаем моргание
  (prevProps, nextProps) => prevProps.selected === nextProps.selected
);
