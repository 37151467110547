import clsx from "clsx";
import { TailSpin } from "react-loader-spinner";
import styles from "./styles.module.scss";

type LoaderProps = {
  size?: number;
  className?: string;
};

const Loader: React.FC<LoaderProps> = (props) => {
  const { size, className } = props;

  return (
    <div className={clsx(styles.container, className)}>
      <TailSpin height={size} width={size} />
    </div>
  );
};

export default Loader;
