import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { ANIMATION_TIME } from "./const";
import styles from "./styles.module.scss";
import animationStyles from "./animation.module.scss";

const overlayAnimation = {
  enter: animationStyles.overlayEnter,
  enterActive: animationStyles.overlayEnterActive,
  exit: animationStyles.overlayExit,
  exitActive: animationStyles.overlayExitActive,
};

const contentAnimation = {
  enter: animationStyles.contentEnter,
  enterActive: animationStyles.contentEnterActive,
  exit: animationStyles.contentExit,
  exitActive: animationStyles.contentExitActive,
};

interface ILayoutProps {
  onClose: () => void;
  children: React.ReactElement;
  opened: boolean;
  withBottomClose?: boolean;
}

const CloseIcon = "../../../icons/icon-close.svg";

export const Layout = ({
  onClose,
  children,
  opened,
  withBottomClose = true,
}: ILayoutProps) => {
  const overlayRef = useRef<any>();
  const contentRef = useRef<any>();

  const [animationIn, setAnimationIn] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    setAnimationIn(opened);
  }, [opened]);

  const renderCloseButtonForMobail = () => {
    if (width < 600 && withBottomClose) {
      return (
        <div onClick={onClose} className={styles.bottomClose}>
          <span className={styles.bottomClose__span}>закрыть</span>
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <CSSTransition
        in={animationIn}
        nodeRef={overlayRef}
        timeout={ANIMATION_TIME}
        mountOnEnter
        unmountOnExit
        classNames={overlayAnimation}
      >
        <div ref={overlayRef} className={styles.overlay} onClick={onClose} />
      </CSSTransition>
      <CSSTransition
        in={animationIn}
        nodeRef={contentRef}
        timeout={ANIMATION_TIME}
        mountOnEnter
        unmountOnExit
        classNames={contentAnimation}
      >
        <div ref={contentRef} className={styles.content}>
          <div className={styles.close}>
            <img
              className={styles.close__icon}
              src={CloseIcon}
              alt="close"
              onClick={onClose}
            />
          </div>
          {children}
          {renderCloseButtonForMobail()}
        </div>
      </CSSTransition>
    </div>
  );
};
